import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { API } from 'aws-amplify';
import { complaintTypesBySocietyId } from '../../../graphql/queries';

import { Formik } from 'formik';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import FormControl from '../../../components/formControl';
import Spinner from '../../../components/spinner';
import { updateComplaintType } from '../../../graphql/mutations';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
const priorityTypr = require('../../../data/complaintPriorityTypa.json');

const ComplaintType = () => {
    const society = useSelector((state) => state.user_society)

    const [complaintType, setComplaintType] = useState([])
    const [spinner, showSpinner] = useState(false)
    const [nextArr, setNextArr] = useState([])
    const [modal, showModal] = useState(false)
    const [formData, setFormData] = useState({
        complaint_name: '',
        priority: '',
        id: '',
    })


    useEffect(() => {
        if (society) {
            complaintTypes()
        }
        // eslint-disable-next-line
    }, [society])

    const complaintTypes = (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: complaintTypesBySocietyId,
                variables: {
                    societyId: society.id,
                    limit: 20,
                    nextToken: token || null
                },
            }).then(res => {
                if (!isEmpty(res?.data?.complaintTypesBySocietyId?.items)) {
                    showSpinner(true)
                    if (res?.data?.complaintTypesBySocietyId?.nextToken !== null && prev) {
                        let newUsers = res?.data?.complaintTypesBySocietyId?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setComplaintType(res?.data?.complaintTypesBySocietyId?.items)
                    showSpinner(false)
                }
                showSpinner(false)
            }
            )
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            complaintTypes(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            complaintTypes(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            complaintTypes(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handleEdit = (complaint) => {
        setFormData({
            complaint_name: complaint?.name,
            priority: { label: complaint?.priorityType, value: complaint?.priorityType },
            id: complaint?.id,
        }
        )
        showModal(true)
    }

    const handleEditComplaintType = async (form) => {
        try {
            let data = await API.graphql({
                query: updateComplaintType,
                variables: {
                    input: {
                        id: formData?.id,
                        name: form?.name,
                        priorityType: form?.priority?.value
                    }
                }
            })
            setComplaintType((prev) => {
                const index = prev?.findIndex((comp) => comp?.id === formData?.id)
                prev[index] = data.data.updateComplaintType
                return [...prev]
            })
            toast.success('Complaint type updated successfully')
            showModal(false)
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <section>
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='table-responsive' key={complaintType?.length}>
                            <Spinner show={spinner}>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th className='ms-5'>Complaint Type</th>
                                            <th className='ms-5'>Priority Type</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {complaintType?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='ms-5'>{item?.name}</td>
                                                <td className='ms-5'>{item?.priorityType}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded="true">
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby="dropdownMenuLink">
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        <div className='card-footer d-flex justify-content-between'>
                            <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                <li className='page-item' >
                                    <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                        <i className='fe fe-arrow-left me-1'></i> Prev
                                    </Link>
                                </li>
                            </ul>
                            <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                <li className='page-item'>
                                    <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                        Next <i className='fe fe-arrow-right ms-1'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Complaint Type</h3>
                    <hr />
                    <Formik initialValues={formData} onSubmit={handleEditComplaintType} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors }) => {
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='complaint_name' title='Complaint Name'
                                        value={values?.complaint_name} error={errors.complaint_name} onChange={handleChange} />

                                    <FormControl type='select' title='Select type' name='priority' placeholder={'Description'} value={values?.priority} options={priorityTypr} error={errors.priority} onChange={handleChange} required={true} />

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default ComplaintType
