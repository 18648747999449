import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../../../layouts/header'
import { API } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useState } from 'react';
import { customSearchVisitorEntries } from '../../../custumQueries/custumQueries';
import { isEmpty } from 'lodash';
import Spinner from '../../../components/spinner';

const Visitors = () => {
    const society = useSelector((state) => state.user_society)

    const [visitor, setVisitor] = useState()
    const [spinner, showSpinner] = useState(false)
    const [nextArr, setNextArr] = useState([])

    const handleVisitors = (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchVisitorEntries,
                variables: {
                    sort: { direction: 'desc', field: 'createdAt' },
                    filter: { societyId: { eq: society.id } },
                    limit: 10,
                    nextToken: token || null
                }
            }).then(res => {
                if (!isEmpty(res?.data?.searchVisitorEntries?.items)) {
                    if (res?.data?.searchVisitorEntries?.nextToken && prev) {
                        let newUsers = res?.data?.searchVisitorEntries?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setVisitor(res?.data?.searchVisitorEntries?.items);
                }
                showSpinner(false)
            }
            )
        } catch (err) {
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            handleVisitors(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            handleVisitors(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            handleVisitors(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handelTimeSlot = (date) => {
        let fromDate = new Date(date).toLocaleDateString()
        const formattedTime = new Date(date).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return (
            <>
                {fromDate}<strong>,</strong> <span className='small'>{formattedTime}</span>
            </>
        )
    }

    useEffect(() => {
        handleVisitors(null, true)
        // eslint-disable-next-line
    }, [])

    return (
        <section>
            <Header title={'Visitors'} />
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <Spinner show={spinner}>
                            <div className='table-responsive'>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className='text-center'>Building</th>
                                            <th className='text-center'>Unit</th>
                                            <th className='text-center'>Phone</th>
                                            <th className='text-center'>Type</th>
                                            <th className='text-center'>Pre Approved</th>
                                            <th className='text-center'>Valid from</th>
                                            <th className='text-center'>Valid To</th>
                                            <th className='text-center'>Actual In time</th>
                                            <th className='text-center'>Actual out time</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {visitor?.map((item, key) => (
                                            <tr key={item.id}>
                                                <td>{item?.visitor?.visitor?.name}</td>
                                                <td className='text-center'>{item?.visitor?.unit?.building?.name}</td>
                                                <td className='text-center'>{item?.visitor?.unit?.name}</td>
                                                <td className='text-center'>{item?.visitor?.visitor?.phone}</td>
                                                <td className='text-center small'>{item?.type}</td>
                                                <td className='text-center'>{item?.visitor?.preApproved ? <span className="badge bg-success-soft">true</span>
                                                    :
                                                    <span className="badge bg-danger-soft">false</span>}</td>
                                                <td className='text-center'>
                                                    {item?.visitor?.preApproved ?
                                                        handelTimeSlot(item?.visitor?.validFrom)
                                                        : <>NA</>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    {item?.visitor?.preApproved ?
                                                        handelTimeSlot(item?.visitor?.validTo)
                                                        : <>NA</>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    {
                                                        item?.entry ? handelTimeSlot(item?.entry) : <>NA</>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    {
                                                        item?.exit ? handelTimeSlot(item?.exit) : item?.entry ? <span className='badge bg-danger-soft'>Not Exited</span> : (!item?.entry && !item?.exit) && <>NA</>
                                                    }
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Spinner>
                        <hr className='m-0' />
                        <div className='card-footer d-flex justify-content-between'>
                            <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                <li className='page-item' >
                                    <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                        <i className='fe fe-arrow-left me-1'></i> Prev
                                    </Link>
                                </li>
                            </ul>
                            <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                <li className='page-item'>
                                    <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                        Next <i className='fe fe-arrow-right ms-1'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Visitors
