import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../../components/spinner'
import Header from '../../../../layouts/header'
import { getSociety } from '../../../../graphql/queries'
import { API } from 'aws-amplify'

function MobileInvoiceDetail() {
    const params = useParams()
    const navigate = useNavigate()

    const [zohoId, setZohoId] = useState()
    const [noZoho, setNoZoho] = useState(true)
    const [spinner, showSpinner] = useState(false)
    const [invoice, setInvoice] = useState()

    const handelGetSociety = () => {
        showSpinner(true)
        API.graphql({
            query: getSociety,
            variables: {
                id: params.societyId
            }
        }).then((res) => {
            if (res?.data?.getSociety?.zohoId)
                setZohoId(res?.data?.getSociety?.zohoId)
            else
                setNoZoho(false)
        })
    }

    useEffect(() => {
        handelGetSociety()
        // eslint-disable-next-line
    }, [])


    const handelGetInvoiceDetails = () => {
        if (zohoId) {
            showSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices/${params.invoiceId}?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setInvoice(res.data.invoice)
                showSpinner(false)
            })
        } else {
            showSpinner(false)
        }
    }

    const handelGoback = () => {
        navigate(`/mobile/${params?.userId}/${params?.societyId}/accounting/invoice`)
    }

    useEffect(() => {
        handelGetInvoiceDetails()
        // eslint-disable-next-line
    }, [zohoId])


    return (
        <section className='container-fluid mt-3 mx-0 px-0 px-lg-0 pt-3'>
            <Spinner show={spinner}>
                <div className="row d-flex justify-content-center p-sm-0">
                    <div className="col-lg-8 mx-auto px-3">
                        <div className='px-3'>
                            <Header title={'Invoice Details'} onclick={handelGoback} buttonTitle={'Back'} />
                        </div>
                        <div className='card box py-5 mx-0 px-0 px-lg-3 mx-2 mx-lg-5'>
                            {
                                !noZoho &&
                                <div className="ribbon ribbon-top-left">
                                    <span className={invoice?.current_sub_status === 'overdue' ? 'bg-danger' : invoice?.current_sub_status === 'draft' ? 'bg-gray' : invoice?.current_sub_status === 'sent' ? 'bg-warning' : invoice?.current_sub_status === 'paid' && 'bg-success'}>{invoice?.current_sub_status}</span>
                                </div>
                            }
                            <div className="card-body">
                                <table className='table table-bordered mb-0'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span className="fs-3">{invoice?.customer_name}<br /></span>
                                                    <span id="tmp_org_address">India</span>
                                                </div>
                                            </td>
                                            <td align="right">
                                                <div className="pcs-entity-title">TAX INVOICE</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <table className="table table-bordered m-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='d-flex justify-content-around'>
                                                        <div>
                                                            <div>#</div>
                                                            <div>Invoice Date</div>
                                                            <div>Due Date</div>
                                                        </div>
                                                        <div>
                                                            <div>:</div>
                                                            <div>:</div>
                                                            <div>:</div>
                                                        </div>
                                                        <div>
                                                            <div> {invoice?.invoice_number}</div>
                                                            <div> {invoice?.date}</div>
                                                            <div> {invoice?.due_date}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='border bg-light px-3 py-1'>
                                        <div>Bill To</div>
                                    </div>
                                    <div className='border p-1 px-3'>
                                        <div className='text-primary'>{invoice?.customer_name}</div>
                                    </div>
                                    <table className="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th className='p-2 ps-3'>#</th>
                                                <th className='p-2 ps-3'>Item & Description</th>
                                                <th className='p-2 ps-3'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='p-2 ps-3'> 1</td>
                                                <td className='p-2 ps-3'>{invoice?.line_items[0]?.name}</td>
                                                <td className='p-2 ps-3'>₹ {invoice?.line_items[0]?.rate}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='d-flex justify-content-around border py-3'>
                                        <div>
                                            <div>Total</div>
                                            <div>Balance Due</div>
                                        </div>
                                        <div>
                                            <div>:</div>
                                            <div>:</div>
                                        </div>
                                        <div>
                                            <div>₹ {invoice?.line_items[0]?.rate}</div>
                                            <div>₹ {invoice?.balance}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spinner>
        </section>
    )
}

export default MobileInvoiceDetail
