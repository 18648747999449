import React from 'react'

function Security() {
    return (
        <h3>
            Security
        </h3>
    )
}

export default Security
