import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';

import { API, Auth } from 'aws-amplify';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/spinner';
import { customSearchUsers } from '../../custumQueries/custumQueries';


const formData = {
    phone_number: '',
    password: 'Buckler@123'
}

const formSchema = Yup.object().shape({
    phone_number: Yup.string().required('Required').matches(/^[6-9]\d{9}$/gi, 'please enter a valid mobile number')
})

const confirmUserSchema = Yup.object().shape({
    code: Yup.number().required('Required')
});

const otpSchema = Yup.object().shape({
    code: Yup.number().required('Required')
});


const Login = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    const [spinner, showSpinner] = useState(false)
    const [page, setPage] = useState(null)
    const [phoneNumber, setPhoneNumber] = useState('')
    const [user, setUser] = useState()
    const [, setSocieties] = useState()

    async function onSubmit(form) {
        showSpinner(true)
        setPhoneNumber(form.phone_number)
        let user = {
            phone: `+91${form.phone_number}`
        }
        try {
            let userData = await Auth.signIn({
                username: `+91${form.phone_number}`,
                password: 'Buckler@123'
            })
            if (userData.attributes && !userData.attributes.email_verified) {
                await Auth.verifyCurrentUserAttribute('email')
                toast.warning('User email not verified enter OTP to verify the email')
                setPage('EMAIL_VERIFY')
                showSpinner(false)
                return;
            }
            let userRole = await API.graphql({
                query: customSearchUsers,
                variables: {
                    filter: { id: { eq: userData.username } }
                }
            }).catch(() => {
            })
            if (userData.username && userRole?.data?.searchUsers?.items[0]?.societies?.items.some((x) => x.role === 'ADMIN')) {
                const myquery = `query SearchUser{
                    searchUsers(filter: {id: {eq: "${userData.username}"}}) {
                        items {
                        societies(filter: {role: {eq: ADMIN}}) {
                            items {
                            role
                            name
                            societyId
                            society {
                                address
                                name
                                id
                            }
                            }
                        }
                        id
                        name
                        photo
                        phone
                        role
                        email
                        gender
                        }
                    }
                  }`
                API.graphql({
                    query: myquery,
                }).then((res) => {
                    dispatch({ type: 'user_profile', payload: res.data.searchUsers.items[0] })
                    dispatch({ type: 'society', payload: res.data.searchUsers.items[0].societies.items });
                    dispatch({ type: 'user_society', payload: res.data.searchUsers.items[0].societies.items[0].society })
                    setSocieties(res.data.searchUsers.items[0].societies)
                    if (res.data.searchUsers.items[0].societies.items.length > 0) {
                        if (userData.challengeName === 'SMS_MFA') {
                            setUser(userData)
                            setPage('VERIFY_OTP')
                            showSpinner(false)
                        }
                    }
                }).catch((err) => {
                    toast.error(err.message)
                    showSpinner(false)
                })
            } else {
                toast.error('User not authorized to any society')
            }
            showSpinner(false)
        }
        catch (error) {
            if (error.message === 'User is not confirmed.') {
                try {
                    toast.warning('User not verified enter OTP to verify the user')
                    await Auth.resendSignUp(user.phone)
                } catch (err) {
                    toast.error(err.message)
                }
                setPage('CONFIRM_USER')
                showSpinner(false)
            } else {
                if (error.message === 'Incorrect username or password.') {
                    toast.error('Phone number does not exist.')
                    showSpinner(false)
                } else {
                    toast.error(error.message)
                    showSpinner(false)
                }
                showSpinner(false)
            }
        }
    }

    const handleSubmit = async (form) => {
        showSpinner(true)
        try {
            const data = await Auth.confirmSignIn(user, form.code, 'SMS_MFA');
            if (data) {
                navigate('/dashboard')
            }
        } catch (error) {
            toast.error(error.message)
            showSpinner(false)
        }
    };


    async function resendConfirmationCode() {
        try {
            if (phoneNumber) {
                await Auth.resendSignUp(`+91${phoneNumber}`);
                toast.success('code send successfuly')
            }
        } catch (err) {
            toast.error(err.message)
        }
    }


    const confirmUserSubmit = async (form) => {
        showSpinner(true)
        try {
            const data = await Auth.confirmSignUp(`+91${phoneNumber}`, form.code,);
            if (data) {
                await Auth.signIn({
                    username: `+91${phoneNumber}`,
                    password: 'Buckler@123'
                }).then(() => {

                })
                showSpinner(false)
                await Auth.verifyCurrentUserAttribute('email')
                toast.warning('User email not verified enter OTP to verify the email')
                setPage('EMAIL_VERIFY')
            }
        } catch (error) {
            toast.error(error.message)
            showSpinner(false)
        }
    };

    const veriyUserEmail = async (form) => {
        Auth.verifyCurrentUserAttributeSubmit('email', form.code)
            .then(async () => {
                const currentUser = await Auth.currentAuthenticatedUser();
                Auth.setPreferredMFA(currentUser, 'SMS')
                    .then(() => {
                        setPage('Main')
                    })
                    .catch((e) => {

                        console.log(e);
                    });


            })
            .catch((e) => {
                console.log(e);
            });
    }

    useEffect(() => {
        dispatch({ type: 'society', payload: '' });
        dispatch({ type: 'user_society', payload: [] });
        dispatch({ type: 'phone', payload: '' });
        // eslint-disable-next-line
    }, [])


    const HandleComponent = () => {
        switch (page) {
            case 'EMAIL_VERIFY':
                return <VerifyEmail handleSubmit={veriyUserEmail} spinner={spinner} />;
            case 'VERIFY_OTP':
                return <VerifyOTP handleSubmit={handleSubmit} spinner={spinner} resendConfirmationCode={resendConfirmationCode} />;
            case 'CONFIRM_USER':
                return <ConfirmUser confirmUserSubmit={confirmUserSubmit} phoneNumber={phoneNumber} showSpinner={showSpinner} />
            default:
                return <Main onSubmit={onSubmit} spinner={spinner} />
        }
    }

    return (
        <>
            <section className='loginPage vh-100 d-flex align-items-center'>
                <div className='d-lg-flex align-items-center bg-white container container-radius p-4 p-lg-0' >
                    <img className='d-none d-lg-block' src='/img/login.png' alt='' />
                    <div className='align-items-center justify-content-center col-lg-6 '>
                        <div className='pb-6'>
                            <img src='/img/logo.png' alt='logo' className='d-block' />
                        </div>
                        <div className='pb-4'>
                            <h1 className='display-4'>Society Admin Portal</h1>
                        </div>
                        <HandleComponent />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Login

const Main = ({ spinner, onSubmit }) => {

    return (
        <div>
            <Formik initialValues={formData} validationSchema={formSchema} onSubmit={onSubmit} validateOnChange={false} validateOnBlur={false}>
                {({ handleSubmit, values, errors, setFieldValue, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label className='form-label mb-0'>Phone <span className='text-danger'>*</span></label>
                            <NumberFormat
                                placeholder='eg: +91 (999) 999-9999'
                                className='form-control'
                                format='+91 (###) ###-####'
                                name='phone_number'
                                mask='_'
                                value={values.phone}
                                onValueChange={({ value }) => {
                                    setFieldValue('phone_number', value);
                                }}
                            />
                            {errors.phone_number && touched.phone_number && <div className='text-danger mt-2 ms-1 h5'>{errors.phone_number}</div>}
                        </div>
                        <Spinner show={spinner}>
                            <div className='float-end mt-5 pb-5'>
                                <button className='btn btn-primary button' type='submit'>Continue</button>
                            </div>
                        </Spinner>
                    </form>
                )}
            </Formik>
        </div>
    )
}

const VerifyOTP = ({ handleSubmit, spinner }) => {
    return (
        <div>
            <Formik initialValues={formData} validationSchema={otpSchema} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <div className='form-group'>
                            <label className='form-label mb-0'>Code<span className='text-danger'> *</span></label>
                            <NumberFormat
                                className='form-control'
                                name='code'
                                value={values.code}
                                onChange={handleChange}
                                placeholder='Enter your OTP'
                            />
                            {errors.code && touched.code && <div className='text-danger mt-2 ms-1 h5'>{errors.code}</div>}
                        </div>
                        <Spinner show={spinner}>
                            <button className='btn w-100 btn-lg btn-primary button' type='submit'>Verify OTP</button>
                        </Spinner>
                    </form>
                )}
            </Formik>
        </div>
    )
}

const ConfirmUser = ({ confirmUserSubmit, spinner, phoneNumber }) => {

    async function resendConfirmationCodeSignUp() {
        try {
            if (phoneNumber) {
                await Auth.resendSignUp(`+91${phoneNumber}`);
            }
            toast.success('User confirmed successfully')
        } catch (err) {
            toast.error(err.message)
        }
    }

    return (
        <>
            <p className='text-muted mb-5'>Enter your OTP to verify conform your account.</p>
            <div>
                <Formik initialValues={formData} validationSchema={confirmUserSchema} onSubmit={confirmUserSubmit} validateOnChange={false} validateOnBlur={false}>
                    {({ handleChange, handleSubmit, values, errors, touched }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label className='form-label mb-0'>Code<span className='text-danger'> *</span></label>
                                <NumberFormat
                                    className='form-control'
                                    name='code'
                                    value={values.code}
                                    onChange={handleChange}
                                    placeholder='Enter OTP to confirm user'
                                />
                                {errors.code && touched.code && <div className='text-danger mt-2 ms-1 h5'>{errors.code}</div>}
                            </div>
                            <Spinner show={spinner}>
                                <button className='btn w-100 btn-lg btn-primary button' type='submit'>Verify OTP</button>
                            </Spinner>

                            <div className='text-center mt-3'>
                                <small className='text-muted text-center'>
                                    <Link onClick={resendConfirmationCodeSignUp}>Resend OTP</Link>
                                </small>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}

const VerifyEmail = ({ handleSubmit, spinner }) => {
    return (
        <>
            <p className='text-muted mb-5'>Enter your OTP to verify your email account.</p>
            <div>
                <Formik initialValues={formData} validationSchema={otpSchema} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
                    {({ handleChange, handleSubmit, values, errors, touched }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <div className='form-group'>
                                <label className='form-label mb-0'>Code<span className='text-danger'> *</span></label>
                                <NumberFormat
                                    className='form-control'
                                    name='code'
                                    value={values.code}
                                    onChange={handleChange}
                                    placeholder='Enter your OTP to verify your email'
                                />
                                {errors.code && touched.code && <div className='text-danger mt-2 ms-1 h5'>{errors.code}</div>}
                            </div>
                            <Spinner show={spinner}>
                                <button className='btn w-100 btn-lg btn-primary button' type='submit'>Verify OTP</button>
                            </Spinner>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}


