import { API } from 'aws-amplify'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import Spinner from '../../../../components/spinner'
import { useEffect } from 'react'
import moment from 'moment'
import { filterPassedTime } from '../../../../helpers/utils'
import axios from 'axios'
import { getSociety } from '../../../../graphql/queries'
import { Modal } from 'react-bootstrap';

function MobileProfitLoss() {
    const params = useParams()

    const [society, setSociety] = useState()
    const [zohoId, setZohoId] = useState()
    const [startedDate, setStartedDate] = useState()
    const [noZoho, setNoZoho] = useState(true)
    const [endedDate, setEndedDate] = useState()
    const [filterModal, showFilterModal] = useState(false)
    const [filterName, setFilterName] = useState('Filter')
    const [sales, setSales] = useState()
    const [invoices, setInvoice] = useState()
    const [spinner, showSpinner] = useState(false)
    const [formData] = useState({
        start_date: '',
        end_date: ''
    })

    const handelGetSociety = () => {
        showSpinner(true)
        API.graphql({
            query: getSociety,
            variables: {
                id: params.societyId
            }
        }).then((res) => {
            if (res?.data?.getSociety?.zohoId)
                setZohoId(res?.data?.getSociety?.zohoId)
            else
                setNoZoho(false)
            setSociety(res?.data?.getSociety)
        })
    }

    useEffect(() => {
        handelGetSociety()
        // eslint-disable-next-line
    }, [])

    const handelGetInvoice = () => {
        if (zohoId) {
            try {
                showSpinner(true)
                axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices?organization_id=${zohoId}`, {
                    headers: {
                        'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                    }
                }).then((res) => {
                    setInvoice(res.data.invoices)
                    showSpinner(false)
                })
            } catch (err) {
                console.log(err)
                showSpinner(false)
            }

        } else {
            showSpinner(false)
        }
    }

    const handelCalculate = (filter) => {
        const total = invoices?.reduce((accumilator, currentValue) => {
            showSpinner(true)
            if (!filter) {
                if (currentValue?.status !== 'draft' && !filter) {
                    accumilator = accumilator + currentValue?.total
                }
            }
            else if (filter) {
                let filterDetails = filter.split(' ')[0]
                if (filterDetails === 'today') {
                    setFilterName('Today')
                    if (currentValue?.status !== 'draft' && currentValue?.date === filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisMonth') {
                    setFilterName('This Month')
                    if (currentValue?.status !== 'draft' && currentValue?.date.split('-')[1] === filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisYear') {
                    setFilterName('This Year')
                    if (currentValue?.status !== 'draft' && currentValue?.date.split('-')[0] === filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'custom') {
                    setFilterName('custom')
                    if ((currentValue?.status !== 'draft') && (currentValue?.date >= filter.split(' ')[1] && currentValue?.date <= filter?.split(' ')[2])) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
            }
            return accumilator
        }, 0)
        setSales(total)
        showSpinner(false)
    }

    const handelGetToday = () => {
        setStartedDate()
        setEndedDate()
        const today = new Date();
        const finalDate = new Date(today).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`today ${finalDate}`)
    }

    const handelGetThisMonth = () => {
        setStartedDate()
        setEndedDate()
        const thisMonth = new Date()
        const finalDate = new Date(thisMonth).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`thisMonth ${finalDate.split('-')[1]}`)
    }

    const handelGetThisYear = () => {
        setStartedDate()
        setEndedDate()
        const thisYear = new Date()
        const finalDate = new Date(thisYear).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`thisYear ${finalDate.split('-')[0]}`)
    }

    const handelApplyCustomFilter = (form) => {
        if ((!startedDate && !form?.start_date) || (!endedDate && !form?.end_date)) {
            return
        }
        const startDate = form?.start_date ? new Date(form?.start_date).toLocaleDateString('en-CA').split('/').reverse().join('-') : startedDate
        const endDate = form?.end_date ? new Date(form?.end_date).toLocaleDateString('en-CA').split('/').reverse().join('-') : endedDate

        setStartedDate(startDate)
        setEndedDate(endDate)

        handelCalculate(`custom ${startDate} ${endDate}`)
        showFilterModal(false)
    }

    const handelClearFilter = () => {
        setFilterName('Filter')
        handelCalculate()
    }

    useEffect(() => {
        handelCalculate()
        // eslint-disable-next-line
    }, [invoices])

    useEffect(() => {
        handelGetInvoice()
        // eslint-disable-next-line
    }, [zohoId])

    return (
        <section>
            <div className='container-fluid pt-4 mx-0 px-0 px-lg-3'>
                <div className="row d-flex justify-content-center p-sm-0 px-1">
                    <div className="col-lg-8 mx-auto">
                        <Spinner show={spinner}>
                            <div className='card py-5 container-lg mx-0 px-0 px-3'>
                                <div className='dropdown'>
                                    <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                        {zohoId && noZoho &&
                                            <button className='btn btn-sm btn-primary'>{(!filterName || filterName === 'Filter') ? <>Filter</> : <>Status: {filterName}</>}</button>
                                        }
                                    </Link>
                                    <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetToday() }}>
                                            Today
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetThisMonth() }}>
                                            This Month
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetThisYear() }}>
                                            This Year
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { showFilterModal(true) }}>
                                            Custom
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelClearFilter() }}>
                                            Clear
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body-lg px-lg-5'>
                                    <div className='text-center d-flex flex-column'>
                                        <p className='mb-0' style={{ 'font-size': '1.3rem' }}>{society?.name}</p>
                                        <p className='mb-1' style={{ 'font-size': '1.7rem' }}>Profit and Loss</p>
                                        <p className='mb-2'>Basis: Accrual</p>
                                        <p style={{ 'font-size': '0.9rem' }}>{filterName === 'Filter' ? <>Overall data upto the date</> : filterName === 'custom' ? <>From {startedDate?.split('-')?.reverse()?.join('/')} to {endedDate?.split('-')?.reverse()?.join('/')}</> : <>Overall date for {filterName}</>} </p>
                                    </div>
                                    {zohoId ?
                                        <div className='container-fluid-lg pt-5'>
                                            <div className='d-flex flex-column'>
                                                <hr className='m-1 mb-0' />
                                                <div className='d-flex justify-content-between bg-light'>
                                                    <strong className='display-5 ms-4 my-0'>ACCOUNT</strong>
                                                    <strong className='display-5 me-4 my-0'>TOTAL</strong>
                                                </div>
                                                <hr className='m-0 mb-0' />
                                                <h3 className='ms-4 mt-2'>Operating Income</h3>
                                                <div className='d-flex justify-content-between text-primary'>
                                                    <div className='ms-4'>Sales</div>
                                                    <div className='me-4'>{sales}</div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <strong className='ms-4'>Total for Operating Income</strong>
                                                    <div className='me-4'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <h3 className='ms-4 mt-2 '>Cost of Goods Sold</h3>
                                                <div className='d-flex justify-content-between text-primary'>
                                                    <div className='ms-4'>Cost of Goods Sold</div>
                                                    <div className='me-4'>0.00</div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <strong className='ms-4'>Total for Cost of Goods Sold</strong>
                                                    <div className='me-4'>0.00</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between p-1 bg-light'>
                                                    <strong className='ms-4 my-0'>Gross Profit</strong>
                                                    <div className='me-4 my-0'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <h3 className='ms-4 mt-2 '>Operating Expense</h3>
                                                <div className='d-flex justify-content-between'>
                                                    <strong className='ms-4'>Total for Operating Expense</strong>
                                                    <div className='me-4'>0.00</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between p-1 bg-light'>
                                                    <strong className='ms-4 my-0'>Operating Profit</strong>
                                                    <div className='me-4 my-0'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <h3 className='ms-4 mt-2 '>Non Operating Income</h3>
                                                <div className='d-flex justify-content-between'>
                                                    <strong className='ms-4'>Total for Non Operating Income</strong>
                                                    <div className='me-4'>0.00</div>
                                                </div>
                                                <hr className='m-1' />
                                                <h3 className='ms-4 mt-2 '>Non Operating Expense</h3>
                                                <div className='d-flex justify-content-between'>
                                                    <strong className='ms-4'>Total for Non Operating Expense</strong>
                                                    <div className='me-4'>0.00</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between p-1 bg-light'>
                                                    <strong className='ms-4 my-0'>Net Profit/Loss</strong>
                                                    <div className='me-4 my-0'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                            </div>
                                        </div> : !noZoho &&
                                        <>
                                            <h4 className='m-0 p-2 text-center bg-warning rounded'>
                                                <i className="fa fa-warning me-2" />
                                                Society is not mapped to the zoho account please contact the admins to map the society
                                            </h4>
                                        </>
                                    }
                                </div>
                            </div>
                        </Spinner>
                    </div>
                </div>
            </div>
            <Modal show={filterModal} onHide={() => showFilterModal(false)}>
                <Modal.Body>
                    <h3>Custom</h3>
                    <hr />
                    <Formik initialValues={formData} onSubmit={handelApplyCustomFilter} validateOnBlur={false} enableReinitialize>
                        {({ handleSubmit, values, touched, setFieldValue }) => {
                            return (
                                <form className='mb-4' onSubmit={handleSubmit}>
                                    <div className="form-group d-flex flex-column">
                                        <label>
                                            Start Date <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            name="start_date"
                                            className="form-control"
                                            filterTime={filterPassedTime}
                                            value={values?.start_date ? values?.start_date : new Date(startedDate)}
                                            dateFormat="MMM d, yyyy"
                                            selected={
                                                values.start_date ? moment(values.start_date).toDate() : startedDate && moment(startedDate).toDate()
                                            }
                                            onChange={(date) => {
                                                setFieldValue("start_date", date);
                                            }}
                                        />
                                        {(!startedDate && !values.start_date) && touched?.start_date && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                    </div>
                                    <div className="form-group d-flex flex-column">
                                        <label className={!values.start_date && "text-muted"}>
                                            End Date <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                            disabled={!values.start_date}
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            name="end_date"
                                            className="form-control"
                                            filterTime={filterPassedTime}
                                            value={values?.end_date ? values?.end_date : new Date(endedDate)}
                                            minDate={new Date(values.start_date)}
                                            dateFormat="MMM d, yyyy"
                                            selected={
                                                values.end_date ? moment(values.end_date).toDate() : endedDate && moment(endedDate).toDate()
                                            }
                                            onChange={(date) => {
                                                setFieldValue("end_date", date);
                                            }}
                                        />
                                        {(!endedDate && !values.end_date) && touched.end_date && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                    </div>

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Apply</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showFilterModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default MobileProfitLoss
