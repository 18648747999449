import React from 'react'
import Sidebar from './sidebar'
import { Outlet } from 'react-router-dom'
import TopNav from './topNav'

const Index = () => {
    return (
        <div>
            <Sidebar />
            <div className='main-content'>
                <TopNav />
                <div className='container-fluid'><Outlet /></div>
            </div>
        </div>
    )
}

export default Index
