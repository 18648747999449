import { createStore } from "redux";

const initialState = {
    user_profile: "",
    society: "",
    user_society: [],
    societyName: '',
    zohoId: null
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case "user_profile":
            return { ...state, user_profile: action.payload }
        case "society":
            return { ...state, society: action.payload }
        case "user_society":
            return { ...state, user_society: action.payload }
        case "societyName":
            return { ...state, societyName: action.payload }
        case "zohoId":
            return { ...state, zohoId: action.payload }
        default:
            return state
    }
}

const loadState = () => {
    try {
        const serializedState = localStorage.getItem("buckler");
        if (!serializedState) return undefined;
        else return JSON.parse(serializedState);
    } catch (err) {
        return undefined;
    }
};

const saveState = (state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem("buckler", serializedState);
    } catch (err) {
        console.log(err);
    }
};

const store = createStore(
    appReducer,
    loadState(),
    window.__REDUX_DEVTOOLS_EXTENSION__ &&
    window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true })
)

store.subscribe(() => {
    saveState(store.getState());
});

export default store;