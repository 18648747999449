import { API } from 'aws-amplify'
import React from 'react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { customGetUnit } from '../../../../custumQueries/custumQueries'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../../../components/spinner'

function ResidentByUnit() {
    const params = useParams()
    const society = useSelector((state) => state.user_society)

    const [spinner, showSpinner] = useState(false)
    const [residents, setResidents] = useState()

    const getResidentsByUnit = async () => {
        showSpinner(true)
        try {
            let data = await API.graphql({
                query: customGetUnit,
                variables: {
                    id: params.id
                }
            })
            setResidents(data.data?.getUnit?.residents?.items)
            showSpinner(false)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getResidentsByUnit()
        // eslint-disable-next-line
    }, [society])

    return (
        <section>
            <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <Spinner show={spinner}>
                        <table className='table table-hover table-nowrap card-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='text-center'>Phone</th>
                                    <th className='text-center'>User Type</th>
                                    <th className='text-center'>Type</th>
                                </tr>
                            </thead>
                            <tbody className='list fs-base'>
                                {residents?.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            {item?.user?.name}
                                        </td>
                                        <td className='text-center'>
                                            {item?.user?.phone}
                                        </td>
                                        <td className='text-center'>
                                            {
                                                item?.approved ?
                                                    <span className='badge bg-success-soft'>Approved</span>
                                                    :
                                                    <span className='badge bg-danger-soft'>Rejected</span>
                                            }
                                        </td>
                                        <td className='text-center'>{item?.type}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Spinner>
                </div>
            </div>
        </section>
    )
}

export default ResidentByUnit
