import React from 'react'
import QRCode from 'react-qr-code'
import { useParams } from 'react-router-dom'

function QrCode() {
    const { id } = useParams()
    return (
        <section>
            <div className='d-flex align-items-center justify-content-center vh-100'>
                <div className='bg-white p-7 container-radus'>
                    <div className='pb-5 ms-4'>
                        <img src='/img/logo.png' alt='logo' className='d-block' />
                    </div>
                    <QRCode
                        value={`https://my.dev.bucklerapp.com/visitor/qr-code/${id}`}
                        viewBox={`0 0 256 256`}
                    />
                </div>
            </div>
        </section>
    )
}

export default QrCode
