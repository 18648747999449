import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import Spinner from '../../../../components/spinner';
import { deleteVechicle } from '../../../../graphql/mutations';
import { useParams } from 'react-router-dom';
import { customGetUnit } from '../../../../custumQueries/custumQueries';

const Vehicles = () => {
    const params = useParams()

    const society = useSelector((store) => store.user_society)


    const [spinner, showSpinner] = useState(false)
    const [vehicle, setVehicle] = useState()

    useEffect(() => {
        getResidentsByUnit()
        // eslint-disable-next-line
    }, [society])

    const getResidentsByUnit = async () => {
        showSpinner(true)
        try {
            let data = await API.graphql({
                query: customGetUnit,
                variables: {
                    id: params.id
                }
            })
            setVehicle(data.data?.getUnit?.vechicles?.items)
            showSpinner(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handleDeleteVehicle = async (id, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Building?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteVechicle,
                    variables: {
                        input: {
                            id: id
                        }
                    }
                }).then(() => {
                    const temp = [...vehicle]
                    temp.splice(index, 1)
                    setVehicle(temp)
                    toast.success('Building deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }


    return (
        <>
            <div className='card' data-list='{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}' id='companiesList'>
                <div className='card-header'>
                    <div className='row align-items-center'>
                        <div className='col'>
                            <form onSubmit={(event) => { event.preventDefault() }}>
                                <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                    <input className='form-control list-search' type='search' placeholder='Search' />
                                    <span className='input-group-text'>
                                        <i className='fe fe-search'></i>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='table-responsive'>
                    <Spinner show={spinner}>
                        <table className='table table-hover card-table'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className='text-center'>Number</th>
                                    <th className='text-center'>Type</th>
                                    <th className='text-center'>Actions</th>
                                </tr>
                            </thead>
                            <tbody className='list fs-base'>
                                {vehicle?.map((item, index) => (
                                    <tr key={item?.id}>
                                        <td>{item?.name}</td>
                                        <td className='text-center'>{item?.number}</td>
                                        <td className='text-center'>{item?.type}</td>
                                        <td className='text-center' onClick={() => handleDeleteVehicle(item.id, index)}><i className='fe fe-trash'></i></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Spinner>
                </div>
            </div>
        </>
    )
}

export default Vehicles