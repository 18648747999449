import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormControl from '../../../components/formControl';
import Spinner from '../../../components/spinner';
import { customSearchUnits } from '../../../custumQueries/custumQueries';
import { updateUnit } from '../../../graphql/mutations';
import Header from '../../../layouts/header';

const ListUnits = () => {
    const navigate = useNavigate();
    const society = useSelector((state) => state.user_society)
    const unitType = require('../../../data/unitType.json')

    const [search, setSearch] = useState('')
    const [nextArr, setNextArr] = useState([])
    const [spinner, showSpinner] = useState(false)
    const [units, setUnits] = useState()
    const [modal, showModal] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        size: '',
        description: '',
        unit_type: '',
        id: ''
    })

    const getUnits = async (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchUnits,
                variables: {
                    filter: { societyId: { eq: society.id } },
                    sort: { direction: 'desc', field: 'createdAt' },
                    limit: 20,
                    nextToken: token || null
                },
            }).then(res => {
                if (!isEmpty(res?.data?.searchUnits?.items)) {
                    showSpinner(true)
                    if (res?.data?.searchUnits?.nextToken !== null && prev) {
                        let newUsers = res?.data?.searchUnits?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setUnits(res?.data?.searchUnits?.items)
                }
                showSpinner(false)
            }
            )
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            getUnits(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            getUnits(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            getUnits(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handleEditUnit = async (form) => {
        showSpinner(true)
        try {
            let response = await API.graphql({
                query: updateUnit,
                variables: {
                    input: {
                        id: formData.id,
                        name: form.name,
                        size: form.size,
                        description: form.description,
                        type: form.unit_type
                    }
                }
            })
            setUnits((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index] = response.data.updateUnit;
                return [...prevState];
            });
            showSpinner(false)
            toast.success('Unit updated successfully')
            showModal(false)
        } catch (err) {
            console.log(err)
        }
    }


    const handleEdit = (form) => {
        setFormData({
            name: form.name,
            size: form.size,
            description: form.description,
            unit_type: form.type,
            id: form.id
        })
        showModal(true)
    }


    const handleGetResident = async (id) => {
        navigate(`/unit/${id}/resident`)
    }

    useEffect(() => {
        getUnits(null, true)
        // eslint-disable-next-line
    }, [])

    const goToAddVehiclePage = () => {
        navigate(`/unit/create`)
    }

    const handelSearchUnit = (e) => {
        if (e.target.value) {
            setSearch(e.target.value)
            API.graphql({
                query: customSearchUnits,
                variables: {
                    filter: { name: { matchPhrasePrefix: e.target.value }, societyId: { eq: society.id } }
                }
            }).then((res) => {
                setUnits(res.data.searchUnits.items)
            })
        } else {
            setSearch('')
            getUnits(nextArr[nextArr.length - 2], false)
        }
    }


    return (
        <>
            <Header title={'Units'} onclick={goToAddVehiclePage} buttonTitle={'Create'} />
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' id='companiesList'>
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form onSubmit={(event) => { event.preventDefault() }}>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' onChange={(e) => handelSearchUnit(e)} />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='table-responsive'>
                            <Spinner show={spinner}>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th className='text-center'>Building Name</th>
                                            <th className='text-center'>Type</th>
                                            <th className='text-center p-0'>description</th>
                                            <th className='text-center'>Size</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {units?.map((item) => (
                                            <tr key={item?.id} style={{ 'cursor': 'pointer' }}>
                                                <td onClick={() => handleGetResident(item?.id)}>{item?.name}</td>
                                                <td className='text-center' onClick={() => handleGetResident(item?.id)}>{item?.building?.name}</td>
                                                <td className='text-center' onClick={() => handleGetResident(item?.id)}>{item?.type}</td>
                                                <td className='text-center p-0' onClick={() => handleGetResident(item?.id)}>{item?.description}</td>
                                                <td className='text-center' onClick={() => handleGetResident(item?.id)}>{item?.size}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        {!search &&
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Society</h3>
                    <hr />
                    <Formik initialValues={formData} onSubmit={handleEditUnit} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors }) => {
                            const listUnitType = values.unit_type ? unitType.filter((x) => x.value === values.unit_type)[0] : null;
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Name' value={values?.name} onChange={handleChange} />

                                    <FormControl type='text' name='description' title='Description' value={values?.description} onChange={handleChange} />

                                    <FormControl type='text' name='size' title='Size' value={values?.size} onChange={handleChange} />

                                    <FormControl type='select1' title='Select type' name='unit_type' value={listUnitType} options={unitType} error={errors.unit_type} onChange={handleChange} />

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ListUnits
