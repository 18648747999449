import { API } from 'aws-amplify';
import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import ReactDatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import FormControl from '../../../components/formControl';
import Spinner from '../../../components/spinner';
import { updateAmenity } from '../../../graphql/mutations';
import { searchAmenities } from '../../../graphql/queries';
import axios from 'axios';
import NumberFormat from 'react-number-format';

function FacilityType() {
    const society = useSelector((state) => state.user_society)
    const zohoId = useSelector((state) => state.zohoId)

    const [nextArr, setNextArr] = useState([])
    const [spinner, showSpinner] = useState()
    const [listFecilities, setListFecilities] = useState()
    const [modal, showModal] = useState(false)
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedEndTime, setSelectedEndTime] = useState(null);

    const [formData, setFormData] = useState({
        id: '',
        facility_type: '',
        paid: '',
        start_time: '',
        end_time: '',
        duration: '',
        allow_to_pay: '',
        min_slot_duration: '',
        books_per_slot: ''
    })

    const formSchema = Yup.object().shape({
        facility_type: Yup.string().required('Required'),
        paid: Yup.string().when("allow_to_pay", {
            is: true,
            then: Yup.string().required('Required.')
        }),
        start_time: Yup.string().required('Required'),
        end_time: Yup.string().required('Required')
    })

    useEffect(() => {
        handleGetfacility(null, true)
        // eslint-disable-next-line
    }, [])

    const handleGetfacility = async (token, prev) => {
        try {
            API.graphql({
                query: searchAmenities,
                variables: {
                    sort: { direction: 'desc', field: 'createdAt' },
                    filter: { societyId: { eq: society.id } },
                    limit: 20,
                    nextToken: token || null
                },
            }).then((res) => {
                if (res?.data?.searchAmenities?.nextToken !== null && prev) {
                    let newUsers = res?.data?.searchAmenities?.nextToken
                    setNextArr((prev) => ([...prev, newUsers]))
                }
                if (!isEmpty(res.data.searchAmenities.items)) {
                    setListFecilities(res.data.searchAmenities.items)
                }
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handleEdit = (facility) => {
        setSelectedStartTime(null)
        setSelectedEndTime(null)
        let Dates = JSON?.parse(facility?.slots)
        setFormData({
            id: facility.id,
            facility_type: facility.name,
            paid: facility.cost ? facility?.cost : '',
            allow_to_pay: !facility.free,
            start_time: new Date(Dates?.slotStartTime),
            end_time: new Date(Dates?.slotEndTime),
            duration: Dates?.slotDuration,
            books_per_slot: Dates?.bookingCountPerSlot
        })
        showModal(true)
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            handleGetfacility(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            handleGetfacility(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            handleGetfacility(nextArr[nextArr.length - 1], true)
        }
    }

    const handleFromSlot = (slots) => {
        let finalSlots = JSON.parse(slots)
        const istStartTime = moment(new Date(moment.utc(finalSlots.slotStartTime).tz('Asia/Kolkata').format()), "HH:mm:ss [GMT]Z (z)").format("h:mm A");
        const istEndTime = moment(new Date(moment.utc(finalSlots.slotEndTime).tz('Asia/Kolkata').format()), "HH:mm:ss [GMT]Z (z)").format("h:mm A");
        const totalSlots = `${istStartTime} To ${istEndTime}`
        return totalSlots
    }

    const handelEditFacilityType = (form) => {
        showSpinner(true)
        let startTime
        let endTime
        if (selectedStartTime) {
            startTime = moment(selectedStartTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        } else {
            startTime = moment(form.start_time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        }
        if (selectedEndTime) {
            endTime = moment(selectedEndTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        } else {
            endTime = moment(form.end_time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        }
        let slots = JSON.stringify({ slotStartTime: startTime, slotEndTime: endTime, slotDuration: formData.duration, bookingCountPerSlot: form?.books_per_slot })
        API.graphql({
            query: updateAmenity,
            variables: {
                input: {
                    id: formData.id,
                    name: form.facility_type,
                    slots: slots,
                    free: !form?.allow_to_pay,
                    cost: form.allow_to_pay ? form.paid : 0,
                    active: true,
                }
            }
        }).then((res) => {
            let data = JSON.stringify({
                name: form.facility_type,
                rate: form.allow_to_pay ? form.paid : 0,
            })
            axios.put(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/items/${res?.data?.updateAmenity?.id}?organization_id=${zohoId}`, data,
                {
                    headers: {
                        'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                        'Content-Type': 'application/json'
                    },
                })
            setListFecilities((prevState) => {
                const index = prevState.findIndex((facility) => facility.id === formData.id);
                prevState[index] = res.data.updateAmenity;
                return [...prevState]
            });
            showSpinner(false)
            showModal(false)
        })
            .catch((err) => {
                console.log(err)
            })
    }

    const handleEndTimeChange = (time) => {
        setSelectedEndTime(time);
    };

    const handleStartTimeChange = (time) => {
        setSelectedStartTime(time)
    }

    return (
        <section>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card" id="companiesList">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <form>
                                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                            <input className="form-control list-search" type="search" placeholder="Search" />
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Spinner show={spinner}>
                                <table className="table table-hover table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th className='p-0'>Name</th>
                                            <th>status</th>
                                            <th className='text-center'>Cost</th>
                                            <th className='text-center p-0'>Slots</th>
                                            <th className='text-center p-0 m-0'>No of Books per slot</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list fs-base">
                                        {
                                            listFecilities?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='p-0'>{item?.name}</td>
                                                    <td>{item?.free ? <>Free of cost</> : <>Payable</>}</td>
                                                    <td className='text-center'>{item?.cost <= 0 ? 'Free' : `Rs. ${item?.cost}`}</td>
                                                    <td className='text-center p-0'>
                                                        {
                                                            handleFromSlot(item.slots)
                                                        }
                                                    </td>
                                                    <td className='text-center p-0 m-0'>{JSON.parse(item?.slots)?.bookingCountPerSlot && <>{JSON.parse(item?.slots)?.bookingCountPerSlot} slots</>}   </td>
                                                    <td className='text-center'>
                                                        <div className='dropdown'>
                                                            <aLink className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                                <i className='fe fe-more-vertical'></i>
                                                            </aLink>
                                                            <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                                <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                    Edit
                                                                </div>
                                                                {/* <div className='dropdown-item cursor-pointer' href='#' onClick={() => { handleDeleteResident(item, index) }}>
                                                                    Delete
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        <div className='d-flex justify-content-between
                        '>
                            <div className={'ps-4 py-3 pe-3 enable'} onClick={onPrev}>
                                <i className='fe fe-arrow-left pe-1' ></i> Prev
                            </div>
                            <div className={'pe-4 py-3 ps-3 enable'} onClick={onNext} >
                                Next <i className="fe fe-arrow-right ps-1"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={modal} onHide={() => showModal(false)}>
                    <Modal.Body>
                        <h3>Edit Facility Type</h3>
                        <hr />
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handelEditFacilityType} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, touched }) => {
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <FormControl type='text' title='facility Name' name='facility_type' placeholder='Complaint Name' value={values.facility_type} error={errors.facility_type} onChange={handleChange} required={true} />
                                        <div>
                                            <label className='form-label mb-0 p-0 d-flex justify-content-between'>
                                                <div className={!values.allow_to_pay ? 'text-muted' : ''}>
                                                    Paid<span className='text-danger'> *</span>
                                                </div>
                                                <div>
                                                    <FormControl
                                                        type="checkbox"
                                                        name="allow_to_pay"
                                                        value={values.allow_to_pay}
                                                        onChange={handleChange}
                                                        className='mb-1 p-0'
                                                        form={true}
                                                    />
                                                </div>
                                            </label>
                                            <FormControl type='money' name='paid' placeholder={values.allow_to_pay ? 'Enter Price' : 'Free'} value={values.allow_to_pay ? values.paid : 0} error={errors.paid} onChange={handleChange} required={true} disabled={!values.allow_to_pay} />
                                        </div>

                                        <h3>Slots</h3>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="form-group d-flex flex-column">
                                                    <label>
                                                        From <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactDatePicker
                                                        selected={selectedStartTime ? selectedStartTime : formData?.start_time}
                                                        onChange={handleStartTimeChange}
                                                        name='start_time'
                                                        value={new Date(values.start_time)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        placeholderText="Select Time"
                                                        className='form-control'
                                                    />
                                                    {(!selectedStartTime && !formData.start_time) && touched.start_time && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group d-flex flex-column">
                                                    <label className={(!selectedStartTime) && 'text-muted'}>
                                                        To <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactDatePicker
                                                        disabled={!selectedStartTime}
                                                        selected={selectedEndTime ? selectedEndTime : formData?.end_time}
                                                        onChange={handleEndTimeChange}
                                                        minTime={new Date().setHours(selectedStartTime?.getHours(), selectedStartTime?.getMinutes() + 30)} // Set the minimum time to 00:00
                                                        maxTime={new Date().setHours(23, 45)}
                                                        value={new Date(values.end_time)}
                                                        name='end_time'
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        placeholderText="Select Time"
                                                        className='form-control'
                                                    />
                                                    {(!selectedEndTime && !formData.end_time) && touched.end_time && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <label className='form-label mb-0'>Number of books per slot <span className='text-danger'>*</span></label>
                                                <NumberFormat
                                                    className='form-control'
                                                    name='books_per_slot'
                                                    value={values.books_per_slot}
                                                    onChange={handleChange}
                                                    placeholder='Enter No of books per slot'
                                                    maxLength={3}
                                                />
                                                {errors.books_per_slot && touched.books_per_slot && <div className='text-danger mt-2 ms-1 h5'>{errors.books_per_slot}</div>}
                                            </div>
                                        </div>
                                        <Spinner show={spinner}>
                                            <button className='btn btn-primary' type='submit'>Update</button>
                                            <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                        </Spinner>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </div>
        </section>
    )
}

export default FacilityType
