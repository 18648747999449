import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Complaint from './complaint'
import ComplaintType from './complaintType'


function ComplaintAdminLayout() {
    const navigate = useNavigate()

    const [page, setPage] = useState('complaint')

    const handleCreateComplaint = () => {
        navigate('/create/complaint-type')
    }


    return (
        <>
            <div className="header">
                <div className="header-body d-flex align-items-end justify-content-between">
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item" onClick={() => setPage('complaint')}>
                                    <Link className={page === 'complaint' ? 'nav-link active' : "nav-link"}>
                                        Complaints
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('complaint_type')}>
                                    <Link className={page === 'complaint_type' ? 'nav-link active' : "nav-link"}>
                                        Complaint Types
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        page === 'complaint_type' &&
                        <div>
                            <button className='btn btn-primary' onClick={handleCreateComplaint}>Create Complaint Type</button>
                        </div>
                    }
                </div>
            </div>
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    {
                        page === 'complaint' && <Complaint />
                    }
                    {
                        page === 'complaint_type' && <ComplaintType />
                    }
                </div>
            </div>
        </>
    )
}

export default ComplaintAdminLayout
