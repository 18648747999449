import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { API } from 'aws-amplify';
import { Link } from 'react-router-dom';

import { Formik } from 'formik';
import { isEmpty } from 'lodash';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FormControl from '../../../components/formControl';
import Spinner from '../../../components/spinner';
import { customSearchBuildings } from '../../../custumQueries/custumQueries';
import { updateBuilding } from '../../../graphql/mutations';
import Header from '../../../layouts/header';

const ListBuildings = () => {

    const society = useSelector((state) => state.user_society)

    const [search, setSearch] = useState('')
    const [nextArr, setNextArr] = useState([])
    const [buildings, setBuildings] = useState([])
    const [spinner, showSpinner] = useState(false)
    const [modal, showModal] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        societyId: ''
    })

    const formSchema = Yup.object().shape({
    })


    useEffect(() => {
        if (society) {
            listBuildings(null, true)
        }
        // eslint-disable-next-line
    }, [society])

    const listBuildings = (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchBuildings,
                variables: {
                    filter: { societyId: { eq: society.id } },
                    sort: { direction: 'desc', field: 'createdAt' },
                    limit: 10,
                    nextToken: token || null
                }
            }).then((res) => {
                if (!isEmpty(res?.data?.searchBuildings?.items)) {
                    showSpinner(true)
                    if (res?.data?.searchBuildings?.nextToken && prev) {
                        let newUsers = res?.data?.searchBuildings?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setBuildings(res?.data?.searchBuildings?.items);
                }
                showSpinner(false)
            }
            )
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            listBuildings(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            listBuildings(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            listBuildings(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handleEdit = (building) => {
        setFormData({
            name: building.name,
            id: building.id,
            societyId: building.societyId
        })
        showModal(true)
    }

    const handleEditBuilding = async (form) => {
        showSpinner(true)
        try {
            let response = await API.graphql({
                query: updateBuilding,
                variables: {
                    input: {
                        id: formData.id,
                        name: form.name
                    }
                }
            })
            setBuildings((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index] = response.data.updateBuilding;
                return [...prevState];
            });
            toast.success('Building updated successfully')
            showSpinner(false)
            showModal(false)

        } catch (err) {
            console.log(err)
        }
    }

    const navigate = useNavigate();
    const createBuilding = () => {
        navigate('/building/create')
    }

    const handelSearchBuilding = (e) => {
        if (e.target.value) {
            setSearch(e.target.value)
            API.graphql({
                query: customSearchBuildings,
                variables: {
                    filter: { name: { matchPhrasePrefix: e.target.value }, societyId: { eq: society.id } }
                }
            }).then((res) => {
                setBuildings(res.data.searchBuildings.items)
            })
        } else {
            setSearch('')
            listBuildings(nextArr[nextArr.length - 2], false)
        }
    }

    return (
        <>
            <Header title='Buildings' onclick={createBuilding} buttonTitle={'Create'} />
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' id='companiesList'>
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form onSubmit={(event) => { event.preventDefault() }}>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' onChange={(e) => handelSearchBuilding(e)} />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className='table-responsive' key={buildings?.length}>
                            <Spinner show={spinner}>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th className='ms-5'>Building Name</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {buildings?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='ms-5'>{item?.name}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded="true">
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby="dropdownMenuLink">
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        {!search &&
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Building</h3>
                    <hr />
                    <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleEditBuilding} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors }) => {

                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Building Name'
                                        value={values?.name} error={errors.name} onChange={handleChange} />

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ListBuildings
