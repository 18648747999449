import { API } from 'aws-amplify'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormControl from '../../../components/formControl'
import Spinner from '../../../components/spinner'
import { createBuilding } from '../../../graphql/mutations'

const CreateBuilding = () => {
    const society = useSelector((store) => store.user_society)
    const navigate = useNavigate();

    const [spinner, setSpinner] = useState(false)


    const formData = {
        building_name: '',
    }

    const formSchema = Yup.object().shape({
        building_name: Yup.string().required('Required'),
    })

    // const handleSocieties = async () => {
    //     await API.graphql({
    //         query: searchSocieties,
    //         variables: {
    //             id: society.id
    //         }
    //     }).then((res) => {
    //         dispatch({ type: 'user_society', payload: res.data?.searchSocieties?.items })
    //     })
    // }


    const handleCnacleCreate = () => {
        navigate(-1)
    }


    const handleCreateBuildings = async (form) => {
        setSpinner(true)
        try {
            await API.graphql({
                query: createBuilding,
                variables: {
                    input: {
                        societyId: society.id,
                        name: form?.building_name,
                    }
                }
            })
            // handleSocieties()
            setTimeout(() => {
                navigate('/building')
                toast.success('Building Created Successfully')
            }, 2000)
        } catch (err) {
            console.log(err)
            setSpinner(false)
        }
    }

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Building
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateBuildings} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, values, errors }) => {
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <FormControl type='text' title='Building Name' name='building_name' placeholder={'Building Name'} value={values.building_name} error={errors.building_name} onChange={handleChange} required={true} />
                                        <Spinner show={spinner}>
                                            <button className='btn w-100 btn-primary' type='submit'>Create Building</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateBuilding
