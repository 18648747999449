import { API, Auth, Storage } from 'aws-amplify'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormControl from '../../../../components/formControl'
import Spinner from '../../../../components/spinner'
import { customSearchBuildings, customSearchUnits } from '../../../../custumQueries/custumQueries'
import { createResident, createUser } from '../../../../graphql/mutations'
import { searchUsers } from '../../../../graphql/queries'

function CreateResident() {
    const navigate = useNavigate();
    const residentType = require('../../../../data/residentData.json')
    const approvedData = require('../../../../data/apporved.json')
    const society = useSelector((store) => store.user_society)
    const allSocieties = useSelector((store) => store.society)

    const [spinner, showSpinner] = useState(false)
    const [buildings, setBuildings] = useState()
    const [units, setUnits] = useState()

    const formData = {
        building: '',
        unit: '',
        resident_type: '',
        approved: '',
        phone: '',
        email: '',
        name: '',
        image: ''
    }

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required.'),
        // eslint-disable-next-line
        email: Yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter valid email').required('Required.'),
        phone: Yup.number().required('Required.'),
        building: Yup.object().required('Required.'),
        unit: Yup.object().required('Required.'),
        resident_type: Yup.string().required('Required.'),
        approved: Yup.string().required('Required.'),
    })

    const handleCreateResidentData = async (id, form) => {
        showSpinner(true)
        try {
            if (id) {
                let approved = form.approved === 'Approve' ? "true" : "false"
                await API.graphql({
                    query: createResident,
                    variables: {
                        input: {
                            name: form.name,
                            buildingId: form.building.id,
                            societyId: society.id,
                            unitId: form.unit.id,
                            userId: id,
                            type: form.resident_type,
                            approved: approved,
                            allow_call: true
                        }
                    }
                })
                setTimeout(() => {
                    navigate('/resident')
                    toast.success('Resident created successfully')
                    showSpinner(false)
                }, 2000)
            }
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }

    }

    const handleCreateResident = async (form) => {
        const userData = {
            phone: `+91${form.phone}`,
            password: 'Buckler@123',
        }
        try {
            let userDetails = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: { email: { eq: form.email.trim().toLowerCase() } }
                }
            })
            if (isEmpty(userDetails?.data?.searchUsers?.items)) {
                const user = await Auth.signUp({
                    username: userData.phone,
                    password: userData.password,
                    attributes: {
                        email: form.email.trim().toLowerCase(),
                        phone_number: `+91${form.phone}`
                    },
                })
                if (user.userSub) {
                    await API.graphql({
                        query: createUser,
                        variables: {
                            input: {
                                id: user.userSub,
                                name: form?.name,
                                email: form.email.trim().toLowerCase(),
                                phone: userData.phone,
                                photo: form.image || ''
                            }
                        }
                    }).then((res) => {
                        if (res) {
                            handleCreateResidentData(res.data.createUser.id, form)
                        }
                    })
                }
            } else {
                toast.error('This email already exists')
                showSpinner(false)
            }
        } catch (err) {
            console.log(err)
            toast.error(err.message)
            showSpinner(false)
        }
    }

    const handleFormDataOptions = async () => {
        try {
            await API.graphql({
                query: customSearchBuildings,
                variables: {
                    filter: { societyId: { eq: society.id } }
                }
            }).then((res) => {
                for (let buildingItem of res?.data?.searchBuildings?.items) {
                    buildingItem.label = buildingItem.name
                    buildingItem.value = buildingItem.id
                }
                setBuildings(res?.data?.searchBuildings?.items)
            })

        } catch (err) {
            console.log(err)
        }
    }

    const handleCnacleCreate = () => {
        navigate(-1)
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`scrappy-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]
        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }

    const handelSetUnit = async (e) => {
        await API.graphql({
            query: customSearchUnits,
            variables: {
                filter: { societyId: { eq: society?.id }, buildingId: { eq: e?.target?.value?.id } }
            }
        }).then((res) => {
            for (let item of res?.data?.searchUnits.items) {
                item.label = item.name
                item.value = item.id
            }
            setUnits(res?.data?.searchUnits.items)
        })
    }

    useEffect(() => {
        handleFormDataOptions()
        // eslint-disable-next-line
    }, [society, allSocieties])

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Resident
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateResident} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, touched }) => {
                                const listResidentType = values.resident_type ? residentType.filter((x) => x.value === values.resident_type)[0] : null;
                                const listApprovedData = values.approved ? approvedData.filter((x) => x.value === values.approved)[0] : null;
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <FormControl type='text' title='Resident Name' name='name' placeholder={'Name'} value={values.name} error={errors.name} onChange={handleChange} required={true} />

                                        <FormControl type='email' title='Email' name='email' placeholder={'Email'} value={values.email} error={errors.email} onChange={handleChange} required={true} />

                                        <FormControl type='phone' title='Phone' name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />

                                        <FormControl type='select' name='building' title='Select building' options={buildings} required={true}
                                            value={values.building} error={errors.building} onChange={(e) => { handleChange(e); handelSetUnit(e) }} />

                                        {values?.building &&

                                            <FormControl type='select' name='unit' title='Select Unit' options={units} required={true}
                                                value={values.unit} error={errors.unit} onChange={handleChange} />
                                        }


                                        <FormControl type='select1' title='Select type' name='resident_type' placeholder={'Description'} value={listResidentType} options={residentType} error={errors.resident_type} onChange={handleChange} required={true} />

                                        <FormControl type='select1' title='Select status' name='approved' placeholder={'Description'} value={listApprovedData} options={approvedData} error={errors.approved} onChange={handleChange} required={true} />

                                        <div className='form-group mt-3'>
                                            {values?.image && <img src={values?.image} alt="" width={'500'} className='preview' />}
                                            <div>
                                                <label>Image</label>
                                                <input name="image" type="file" accept='image/*' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className="form-control" />
                                                {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                            </div>
                                        </div>

                                        <Spinner show={spinner}>
                                            <button className='btn w-100 btn-primary' type='submit'>Create Resident</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                        <div className='btn w-100 btn-link text-muted text-start' type='button'>
                                            User Already Exist? <Link to={`/resident/add`}>Click here</Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateResident
