import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Vehicles from '../vehicle/vehicles'
// import Conmlaints from '../complaints/complaints'
import Billing from '../billing/billing'
import Security from '../security/security'
import Settings from '../settings/settings'
import ResidentByUnit from './residentByUnit'


function ResidentLayoutByUnit() {
    const navigate = useNavigate()

    const [page, setPage] = useState('resident')

    const createResident = () => {
        navigate(-1)
    }


    return (
        <>
            <div className="header">
                <div className="header-body d-flex align-items-end justify-content-between">
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item" onClick={() => setPage('resident')}>
                                    <Link className={page === 'resident' ? 'nav-link active' : "nav-link"}>
                                        Residents
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('billing')}>
                                    <Link className={page === 'billing' ? 'nav-link active' : "nav-link"}>
                                        Billing
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('security')}>
                                    <Link className={page === 'security' ? 'nav-link active' : "nav-link"}>
                                        Security
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('settings')}>
                                    <Link className={page === 'settings' ? 'nav-link active' : "nav-link"}>
                                        Settings
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('vehicles')}>
                                    <Link className={page === 'vehicles' ? 'nav-link active' : "nav-link"}>
                                        Vehicles
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <button className='btn btn-primary' onClick={createResident}>Back</button>
                    </div>
                </div>
            </div>
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    {page === 'resident' &&
                        <ResidentByUnit />
                    }
                    {page === 'vehicles' &&
                        <Vehicles />
                    }
                    {page === 'settings' &&
                        <Settings />
                    }
                    {page === 'billing' &&
                        <Billing />
                    }
                    {page === 'security' &&
                        <Security />
                    }
                </div>
            </div>
        </>
    )
}

export default ResidentLayoutByUnit
