import React from 'react'
import Header from '../../../../layouts/header'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import Spinner from '../../../../components/spinner'
import swal from 'sweetalert'
import { toast } from 'react-toastify'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker";
import { filterPassedTime } from '../../../../helpers/utils'
import { Formik } from 'formik'
import moment from 'moment'

function RecurringInvoice() {
    const zohoId = useSelector(store => store.zohoId)
    const navigate = useNavigate()

    const [modalSpinner, showModalSpinner] = useState(false)
    const [modal, showModal] = useState(false)
    const [spinner, showSpinner] = useState(false)
    const [recurringInvoice, setRecurringInvoice] = useState()
    const [formData, setFormData] = useState({
        id: '',
        unit: '',
        date: '',
        end_date: '',
        amount: '',
        item_id: '',
        item_name: '',
        rate: '',
    })

    const handelGetRecurringInvoice = () => {
        if (zohoId) {
            showSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setRecurringInvoice(res.data.recurring_invoices)
                showSpinner(false)
            }).catch((err) => {
                showSpinner(false)
            })
        }
    }

    useEffect(() => {
        handelGetRecurringInvoice()
        // eslint-disable-next-line
    }, [])

    const handleAddInvoices = () => {
        navigate('/accounting/create/recurring-invoice')
    }

    const handleEdit = (item, status) => {
        if (status) {
            showModal(true)
            showModalSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices/${item.recurring_invoice_id}?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setFormData({
                    id: item.recurring_invoice_id,
                    unit: item?.customer_name,
                    // eslint-disable-next-line
                    date: new Date(item?.start_date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
                    end_date: new Date(item?.end_date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
                    amount: item.total,
                    item_id: res.data.recurring_invoice.line_items[0].item_id,
                    item_name: res.data.recurring_invoice.line_items[0].name,
                    rate: res.data.recurring_invoice.rate,
                })
                showModalSpinner(false)
            })
        } else {
            toast.error('Invoice never ends can not be editable')
        }
    }

    const handelDeleteInvoice = (invoices, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Invoice?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                showSpinner(true)
                axios.delete(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices/${invoices.recurring_invoice_id
                    }?organization_id=${zohoId}`, {
                    headers: {
                        'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                        'Content-Type': 'application/json'
                    }
                }).then((res) => {
                    const temp = [...recurringInvoice]
                    temp.splice(index, 1)
                    setRecurringInvoice(temp)
                    toast.success('Invoice deleted successfully')
                    showSpinner(false)
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    const handelUpdateRecurringInvoice = (form) => {
        try {
            showSpinner(true)
            let data =
            {
                "date": new Date(form.date).toISOString().split('T')[0],
                "end_date": new Date(form.end_date).toISOString().split('T')[0],
                "line_items": [
                    {
                        "item_id": formData.item_id,
                        "rate": form.amount,
                    }
                ],
            }
            axios.put(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices/${formData.id}?organization_id=${zohoId}`, data, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                setRecurringInvoice((prevState) => {
                    const index = prevState.findIndex((invoice) => invoice.recurring_invoice_id === formData.id);
                    prevState[index] = res.data.recurring_invoice;
                    return [...prevState];
                })
                toast.success('Updated successfully')
                showModal(false)
                showSpinner(false)
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    return (
        <section>
            <Header title={'Recurring Invoice'} onclick={handleAddInvoices} buttonTitle='Create Recurring Invoice' />
            <div className='tab-content pb-3 d-none d-xl-block'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' id='companiesList'>
                        <div className='table-responsive'>
                            <Spinner show={spinner}>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th className='ms-5'>Unit Name</th>
                                            <th className='text-center'>Next Invoice Date</th>
                                            <th className='text-center'>End Invoice date</th>
                                            <th className='text-center'>Frequency</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Total Amount</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base'>
                                        {zohoId ? recurringInvoice?.map((item, index) => (
                                            <tr key={index}>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='ms-5 p-0 cursor-pointer'>{item?.customer_name}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='text-center cursor-pointer'>{item.status !== 'expired' ? item.next_invoice_date : 'NA'}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='text-center cursor-pointer'>{item?.end_date ? <>{item?.end_date}</> : <span className='text-muted'>Never Ends</span>}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='text-center cursor-pointer'>{
                                                    item?.repeat_every > 1 ?
                                                        <>
                                                            {
                                                                item?.recurrence_frequency === 'years' ? <span>Every {item?.repeat_every} Years</span> :
                                                                    item?.recurrence_frequency === 'weeks' ? <span>Every {item?.repeat_every} Weeks</span> :
                                                                        item?.recurrence_frequency === 'months' ? <span>Every {item?.repeat_every} Months</span> : <></>

                                                            }
                                                        </> :
                                                        <>
                                                            {
                                                                item?.recurrence_frequency === 'years' ? <span>Yearly</span> :
                                                                    item?.recurrence_frequency === 'weeks' ? <span>Weekly</span> :
                                                                        item?.recurrence_frequency === 'months' ? <span>Monthly</span> : <></>
                                                            }
                                                        </>
                                                }</td>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='text-center cursor-pointer'>{item.status === 'expired' ? <span className='text-danger'>EXPIRED</span> : <span className='text-success'>ACTIVE</span>}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)} className='text-center cursor-pointer'>₹ {item?.total}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item, item?.end_date ? true : false) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handelDeleteInvoice(item, index) }}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                            <tr className='text-center py-3'>
                                                <td colSpan={'7'}>
                                                    <h3 className='m-0 text-yellow'>
                                                        <i className="fa fa-warning me-2" />
                                                        Society is not mapped to the zoho account please contact the admins to map the society
                                                    </h3>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-lg-block d-xl-none p-0">
                <Spinner show={spinner}>
                    {
                        zohoId ? recurringInvoice?.map((item, index) => (
                            <div key={index}>
                                <div>
                                    <div className='d-flex justify-content-between bg-white rounded' onClick={() => navigate(`/accounting/${item?.recurring_invoice_id}/recurring-invoice`)}>
                                        <div className="ps-3 text-start d-flex">
                                            <div onClick={(e) => { e.stopPropagation() }}>
                                                <i className="fa fa-trash-o text-danger" onClick={() => { handelDeleteInvoice(item, index) }} /><br /><br />
                                                <i className='fe fe-edit text-primary' onClick={() => { handleEdit(item, item?.end_date ? true : false) }} />
                                            </div>
                                            <div>
                                                <div className="ps-3 text-start">
                                                    <strong>{item?.customer_name}</strong>
                                                    <div>{item?.recurrence_name}</div>
                                                    {
                                                        item.next_invoice_date ?
                                                            <div className='text-muted'>Next Invoice on {item.next_invoice_date}</div> :
                                                            <div className='text-muted'>Next Invoice is NA</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pe-3 text-end">
                                            <div>₹ {item?.total}</div>
                                            <div>{item.status === 'expired' ? <span className='text-danger'>EXPIRED</span> : <span className='text-success'>ACTIVE</span>}</div>
                                            <div>
                                                {
                                                    item?.repeat_every > 1 ?
                                                        <>
                                                            {
                                                                item?.recurrence_frequency === 'years' ? <span>Every {item?.repeat_every} Years</span> :
                                                                    item?.recurrence_frequency === 'weeks' ? <span>Every {item?.repeat_every} Weeks</span> :
                                                                        item?.recurrence_frequency === 'months' ? <span>Every {item?.repeat_every} Months</span> : <></>

                                                            }
                                                        </> :
                                                        <>
                                                            {
                                                                item?.recurrence_frequency === 'years' ? <span>Yearly</span> :
                                                                    item?.recurrence_frequency === 'weeks' ? <span>Weekly</span> :
                                                                        item?.recurrence_frequency === 'months' ? <span>Monthly</span> : <></>
                                                            }
                                                        </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='p-1 m-1' />
                            </div>
                        ))
                            :
                            <h3 className='m-0 text-yellow'>
                                <i className="fa fa-warning me-2" />
                                Society is not mapped to the zoho account please contact the admins to map the society
                            </h3>
                    }
                </Spinner>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Recurring Invoice</h3>
                    <hr />
                    <Spinner show={modalSpinner}>
                        <Formik initialValues={formData} onSubmit={handelUpdateRecurringInvoice} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, touched, setFieldValue }) => {
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Unit Name<span className='text-danger'>*</span></label>
                                            <input className='form-control bg-light' type='text' name='unit' placeholder='e.g. Investor' value={values.unit} onChange={handleChange} disabled />
                                        </div>

                                        <div className="form-group d-flex flex-column">
                                            <label>
                                                Next Invoice Date <span className="text-danger">*</span>
                                            </label>
                                            <DatePicker
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete="off"
                                                name="date"
                                                disabled
                                                className="form-control bg-light"
                                                filterTime={filterPassedTime}
                                                minDate={moment().toDate()}
                                                dateFormat="MMM d, yyyy"
                                                selected={
                                                    values.date ? moment(values.date).toDate() : ""
                                                }
                                                onChange={(date) => {
                                                    setFieldValue("date", date);
                                                }}
                                            />
                                            {errors.date && touched.date && (
                                                <div className="text-danger mt-2 ms-1 h5">
                                                    {errors.date}
                                                </div>
                                            )}
                                        </div>

                                        <div className="form-group d-flex flex-column">
                                            <label className={!values.date && "disable"}>
                                                Ends On <span className="text-danger">*</span>
                                            </label>
                                            <DatePicker
                                                disabled={!values.date}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete="off"
                                                name="end_date"
                                                className="form-control"
                                                filterTime={filterPassedTime}
                                                minDate={new Date(values.date)}
                                                dateFormat="MMM d, yyyy"
                                                selected={
                                                    values.end_date ? moment(values.end_date).toDate() : ""
                                                }
                                                onChange={(date) => {
                                                    setFieldValue("end_date", date);
                                                }}
                                            />
                                            {errors.end_date && touched.end_date && (
                                                <div className="text-danger mt-2 ms-1 h5">
                                                    {errors.end_date}
                                                </div>
                                            )}
                                        </div>

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Amount<span className='text-danger'> *</span></label>
                                            <input className='form-control bg-light' type='money' name='amount' placeholder='Amount' value={`Rs.${values.amount}`} disabled />
                                        </div>

                                        <Spinner show={spinner}>
                                            <button className='btn btn-primary' type='submit'>Update</button>
                                            <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                        </Spinner>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </Spinner>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default RecurringInvoice
