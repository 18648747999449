import { API } from 'aws-amplify'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import DatePicker from "react-datepicker";
import Spinner from '../../../../components/spinner'
import { useEffect } from 'react'
import moment from 'moment'
import { filterPassedTime } from '../../../../helpers/utils'
import axios from 'axios'
import { getSociety } from '../../../../graphql/queries'
import { Modal } from 'react-bootstrap';

function MobileBalanceSheet() {
    const params = useParams()

    const [zohoId, setZohoId] = useState()
    const [society, setSociety] = useState()
    const [endedDate, setEndedDate] = useState()
    const [currentDate, setCurrentDate] = useState()
    const [filterModal, showFilterModal] = useState(false)
    const [filterName, setFilterName] = useState('Filter')
    const [noZoho, setNoZoho] = useState(true)
    const [invoices, setInvoice] = useState()
    const [spinner, showSpinner] = useState(false)
    const [paid, setPaid] = useState()
    const [sales, setSales] = useState()
    const formData = {
        end_date: '',
    }

    const handelGetSociety = () => {
        showSpinner(true)
        API.graphql({
            query: getSociety,
            variables: {
                id: params.societyId
            }
        }).then((res) => {
            if (res?.data?.getSociety?.zohoId)
                setZohoId(res?.data?.getSociety?.zohoId)
            else
                setNoZoho(false)
            setSociety(res?.data?.getSociety)
        })
    }

    useEffect(() => {
        handelGetSociety()
        // eslint-disable-next-line
    }, [])

    const handelGetInvoice = () => {
        if (zohoId) {
            showSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setInvoice(res.data.invoices)
                showSpinner(false)
            })
        } else {
            showSpinner(false)
        }
    }

    const handelCalculate = (filter) => {
        let lastMonthDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0).toLocaleDateString('en-CA').split('/').reverse().join('-');
        setCurrentDate(lastMonthDate)

        const total = invoices?.reduce((accumilator, currentValue) => {
            if (!filter) {
                if (currentValue?.status !== 'draft' && !filter && currentValue?.date <= lastMonthDate) {
                    accumilator = accumilator + currentValue?.total
                }
            }
            else if (filter) {
                let filterDetails = filter.split(' ')[0]
                if (filterDetails === 'today') {
                    setFilterName('Today')
                    if (currentValue?.status !== 'draft' && currentValue?.date <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisMonth') {
                    setFilterName('This Month')
                    if (currentValue?.status !== 'draft' && currentValue?.date.split('-') <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisYear') {
                    setFilterName('This Year')
                    if (currentValue?.status !== 'draft' && currentValue?.date.split('-') <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'custom') {
                    setFilterName('custom')
                    if ((currentValue?.status !== 'draft') && ((currentValue?.date >= filter.split(' ')[1]) && (currentValue?.date <= filter?.split(' ')[2]))) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
            }
            return accumilator
        }, 0)

        const paidInvoice = invoices?.reduce((accumilator, currentValue) => {
            if (!filter) {
                if (currentValue?.status === 'paid' && !filter && currentValue?.date <= lastMonthDate) {
                    accumilator = accumilator + currentValue?.total
                }
            }
            else if (filter) {
                let filterDetails = filter.split(' ')[0]
                if (filterDetails === 'today') {
                    setFilterName('Today')
                    if (currentValue?.status === 'paid' && currentValue?.date <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisMonth') {
                    setFilterName('This Month')
                    if (currentValue?.status === 'paid' && currentValue?.date.split('-')[1] <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'thisYear') {
                    setFilterName('This Year')
                    if (currentValue?.status === 'paid' && currentValue?.date.split('-')[0] <= filter.split(' ')[1]) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
                if (filterDetails === 'custom') {
                    setFilterName('custom')
                    if ((currentValue?.status === 'paid') && (currentValue?.date >= filter.split(' ')[1] && currentValue?.date <= filter?.split(' ')[2])) {
                        accumilator = accumilator + currentValue?.total
                    }
                }
            }
            return accumilator
        }, 0)

        setSales(total)
        setPaid(paidInvoice)
    }

    const handelGetToday = () => {
        const today = new Date();
        const finalDate = new Date(today).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`today ${finalDate}`)
    }

    const handelGetThisMonth = () => {
        const thisMonth = new Date()
        const finalDate = new Date(thisMonth).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`thisMonth ${finalDate.split('-')}`)
    }

    const handelGetThisYear = () => {
        const thisYear = new Date()
        const finalDate = new Date(thisYear).toLocaleDateString('en-CA').split('/').reverse().join('-')

        handelCalculate(`thisYear ${finalDate.split('-')}`)
    }

    const handelApplyCustomFilter = (form) => {
        const selectedYear = form?.end_date ? form?.end_date.getFullYear() : endedDate.split('-')[0];
        const selectedMonth = form?.end_date ? form?.end_date.getMonth() + 1 : endedDate.split('-')[0];
        const financialYearStartMonth = 4; // April is month index 3

        let financialYearStartYear;
        if (selectedMonth >= financialYearStartMonth) {
            financialYearStartYear = selectedYear;
        } else {
            financialYearStartYear = selectedYear - 1;
        }

        const startDate = `${financialYearStartYear}-${financialYearStartMonth.toString().padStart(2, '0')}-01`
        const endDate = form?.end_date ? new Date(form?.end_date).toLocaleDateString('en-CA').split('/').reverse().join('-') : endedDate

        setEndedDate(endDate)

        handelCalculate(`custom ${startDate} ${endDate}`)
        showFilterModal(false)
    }

    const handelClearFilter = () => {
        setFilterName('Filter')
        handelCalculate()
    }

    useEffect(() => {
        handelCalculate()
        // eslint-disable-next-line
    }, [invoices])

    useEffect(() => {
        handelGetInvoice()
        // eslint-disable-next-line
    }, [zohoId])


    return (
        <section>
            <div className='container-fluid pt-4 mx-0 px-0 px-lg-3'>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-8 mx-auto">
                        <Spinner show={spinner}>
                            <div className='card py-5 container-lg mx-0 px-0 px-3'>
                                <div className='dropdown'>
                                    <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                        {zohoId && noZoho &&
                                            <button className='btn btn-sm btn-primary'>{(!filterName || filterName === 'Filter') ? <>Filter</> : <>Status: {filterName}</>}</button>
                                        }
                                    </Link>
                                    <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetToday() }}>
                                            Today
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetThisMonth() }}>
                                            This Month
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelGetThisYear() }}>
                                            This Year
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { showFilterModal(true) }}>
                                            Custom
                                        </div>
                                        <div className='dropdown-item cursor-pointer' onClick={() => { handelClearFilter() }}>
                                            Clear
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body-lg px-lg-5'>
                                    <div className='text-center d-flex flex-column'>
                                        <p className='mb-0' style={{ 'font-size': '1.3rem' }}>{society?.name}</p>
                                        <p className='mb-1' style={{ 'font-size': '1.7rem' }}>Balance Sheet</p>
                                        <p className='mb-2'>Basis: Accrual</p>
                                        <p style={{ 'font-size': '0.9rem' }}>{filterName === 'Filter' ? <>As of {currentDate?.split('-')?.reverse()?.join('/')}</> : filterName === 'custom' ? <>As of {endedDate?.split('-')?.reverse()?.join('/')}</> : <>As of {filterName}</>} </p>
                                    </div>
                                    {zohoId ?
                                        <div className='container-fluid-lg pt-5'>
                                            <div className='d-flex flex-column'>
                                                <hr className='m-1 mb-0' />
                                                <div className='d-flex justify-content-between bg-light'>
                                                    <strong className='display-5 ms-4 my-0'>ACCOUNT</strong>
                                                    <strong className='display-5 me-4 my-0'>TOTAL</strong>
                                                </div>
                                                <hr className='m-0 mb-0' />
                                                <h3 className='ms-4 mt-2'>Assets</h3>
                                                <h3 className='ms-4 mt-2'>Current Assets</h3>
                                                <h3 className='ms-4 mt-2'>Cash</h3>
                                                <div className='d-flex justify-content-between text-primary'>
                                                    <div className='ms-4'> Petty Cash</div>
                                                    <div className='me-4'>{paid?.toFixed(2)}</div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <strong className='ms-4'>Total for Cash</strong>
                                                    <div className='me-4'>{paid?.toFixed(2)}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between text-primary'>
                                                    <div className='ms-4'> Accounts Receivable</div>
                                                    <div className='me-4'>{sales - paid}</div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <strong className='ms-4'> Total for Current Assets</strong>
                                                    <div className='me-4'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between bg-light'>
                                                    <strong className='ms-4 my-0'>Total for Assets</strong>
                                                    <div className='me-4 my-0'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <h3 className='ms-4 mt-2'>Liabilities & Equities</h3>
                                                <h3 className='ms-4 mt-2'>Equities</h3>
                                                <div className='d-flex justify-content-between text-primary'>
                                                    <div className='ms-4'>  Current Year Earnings</div>
                                                    <div className='me-4'>{sales}</div>
                                                </div>
                                                <div className='d-flex justify-content-between mt-3'>
                                                    <strong className='ms-4'> Total for Equities</strong>
                                                    <div className='me-4'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                                <div className='d-flex justify-content-between p-1 bg-light'>
                                                    <strong className='ms-4 my-0 p-0'>Total for Current Assets</strong>
                                                    <div className='me-4 my-0'>{sales}</div>
                                                </div>
                                                <hr className='m-1' />
                                            </div>
                                        </div> : !noZoho &&
                                        <>
                                            <h4 className='m-0 p-2 text-center bg-warning rounded'>
                                                <i className="fa fa-warning me-2" />
                                                Society is not mapped to the zoho account please contact the admins to map the society
                                            </h4>
                                        </>
                                    }
                                </div>
                            </div>
                        </Spinner>
                    </div>
                </div>
            </div >
            <Modal show={filterModal} onHide={() => showFilterModal(false)}>
                <Modal.Body>
                    <h3>Custom</h3>
                    <hr />
                    <Formik initialValues={formData} onSubmit={handelApplyCustomFilter} validateOnBlur={false} enableReinitialize>
                        {({ handleSubmit, values, touched, setFieldValue }) => {
                            return (
                                <form className='mb-4' onSubmit={handleSubmit}>
                                    <div className="form-group d-flex flex-column">
                                        <label>
                                            Select Date <span className="text-danger">*</span>
                                        </label>
                                        <DatePicker
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            autoComplete="off"
                                            name="end_date"
                                            className="form-control"
                                            filterTime={filterPassedTime}
                                            value={values?.end_date ? values?.end_date : new Date(endedDate)}
                                            dateFormat="MMM d, yyyy"
                                            selected={
                                                values.end_date ? moment(values.end_date).toDate() : endedDate && moment(endedDate).toDate()
                                            }
                                            onChange={(date) => {
                                                setFieldValue("end_date", date);
                                            }}
                                        />
                                        {(!endedDate && !values.end_date) && touched.end_date && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                    </div>
                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Apply</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showFilterModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section >
    )
}

export default MobileBalanceSheet
