import axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker"
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import Spinner from '../../../../components/spinner'
import { filterPassedTime } from '../../../../helpers/utils'
import { getSociety } from '../../../../graphql/queries'
import { API } from 'aws-amplify'
import { customSearchUnit } from '../../../../custumQueries/custumQueries'
import FormControl from '../../../../components/formControl'

const MobileCreateInvoice = () => {
    const params = useParams()
    const navigate = useNavigate();

    const [society, setSociety] = useState()
    const [zohoId, setZohoId] = useState()
    const [spinner, showSpinner] = useState(false)
    const [formSpinner, showFormSpinner] = useState(false)
    const [noZoho, setNoZoho] = useState(true)
    const [units, setUnits] = useState()
    const [invoices, setInvoices] = useState()

    const formData = {
        unit: '',
        invoice: '',
        date: '',
        amount: ''
    }

    const handelGetSociety = () => {
        showSpinner(true)
        API.graphql({
            query: getSociety,
            variables: {
                id: params.societyId
            }
        }).then((res) => {
            if (res?.data?.getSociety?.zohoId) {
                setZohoId(res?.data?.getSociety?.zohoId)
            } else {
                setNoZoho(false)
            }
            setSociety(res?.data?.getSociety)
        })
    }

    useEffect(() => {
        handelGetSociety()
        // eslint-disable-next-line
    }, [])

    const handelGetInvoiceItem = () => {
        showFormSpinner(true)
        if (zohoId) {
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/items?organization_id=${zohoId}`, {
                headers: {
                    "x-api-key": "bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H"
                }
            }).then(
                (res) => {
                    for (let item of res.data.items) {
                        item.label = item.name
                        item.value = item.item_id
                    }
                    setInvoices(res.data.items)
                    showFormSpinner(false)
                }).catch((err) => {
                    console.log(err)
                })
        } else {
            showSpinner(false)
        }
    }

    const handleFormDataOptions = async () => {
        showSpinner(true)
        try {
            await API.graphql({
                query: customSearchUnit,
                variables: {
                    filter: { societyId: { eq: society.id } }
                }
            }).then((res) => {
                for (let item of res?.data?.searchUnits?.items) {
                    item.label = item.name
                    item.value = item.id
                }
                setUnits(res?.data?.searchUnits?.items)
                showSpinner(false)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const formSchema = Yup.object().shape({
        unit: Yup.object().required('Required.'),
        invoice: Yup.object().required('Required.'),
        date: Yup.string().required('Required.'),
        amount: Yup.number().required('Required.')
    })

    const handleCnacleCreate = () => {
        navigate(`/mobile/${params?.userId}/${params?.societyId}/accounting/invoice`)
    }

    const handleCreateInvoice = async (form) => {
        showSpinner(true)
        let address = JSON.parse(society.address)
        let data =
        {
            "date": new Date(form.date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
            "due_date": new Date(form.due_date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
            "customer_id": form.unit.zohoId,
            "customer_name": form.unit.name,
            "currency_id": "3769395000000000099",
            "currency_code": "INR",
            "exchange_rate": 1,
            "line_items": [
                {
                    "item_id": form.invoice.item_id,
                    "name": form.invoice.item_name,
                    "item_name": form.invoice.item_name,
                    "status": "active",
                    "rate": form.amount ? form.amount : 0,
                }
            ],
            "total": form.amount ? form.amount : 0,
            "payment_reminder_enabled": true,
            "balance": form.amount,
            "allow_partial_payments": true,
            "billing_address": {
                "address": address.city ? address.city.trim() : '',
                "city": address.city ? address.city : '',
                "state": address.state ? address.state : '',
                "zip": address.zip ? address.zip : '',
                "country": "INDIA",
            },
            "custom_fields": [
                {
                    "value": form.unit.building.name
                }
            ],
            "can_send_in_mail": true
        }
        axios.post(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices?organization_id=${zohoId}`, data, {
            headers: {
                'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                'Content-Type': 'application/json'
            }
        }).then(() => {
            showSpinner(false)
            navigate(`/mobile/${params.userId}/${params.societyId}/accounting/invoice`)
            toast.success('Invoice created successfully')
        })
    }

    useEffect(() => {
        handleFormDataOptions()
        handelGetInvoiceItem()
        // eslint-disable-next-line
    }, [zohoId])

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society?.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Invoice
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            zohoId ?
                                <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateInvoice} validateOnChange={false} validateOnBlur={false}>
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {
                                        return (
                                            <Spinner show={formSpinner}>
                                                <form className='mb-4' onSubmit={handleSubmit}>

                                                    <FormControl type='select' name='unit' title='Select Unit' options={units} required={true}
                                                        value={values.unit} error={errors.unit} onChange={handleChange} />

                                                    <FormControl type='select' name='invoice' title='Select Invoice' options={invoices} required={true}
                                                        value={values.invoice} error={errors.invoice} onChange={(e) => { handleChange(e); setFieldValue('amount', e.target.value.rate ? e.target.value.rate : 0) }} />


                                                    <div className="form-group d-flex flex-column">
                                                        <label>
                                                            Invoice Date <span className="text-danger">*</span>
                                                        </label>
                                                        <DatePicker
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            name="date"
                                                            className="form-control"
                                                            filterTime={filterPassedTime}
                                                            minDate={moment().toDate()}
                                                            dateFormat="MMM d, yyyy"
                                                            selected={
                                                                values.date ? moment(values.date).toDate() : ""
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue("date", date);
                                                            }}
                                                        />
                                                        {errors.date && touched.date && (
                                                            <div className="text-danger mt-2 ms-1 h5">
                                                                {errors.date}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="form-group d-flex flex-column">
                                                        <label className={!values.date && "disable"}>
                                                            Due Date <span className="text-danger">*</span>
                                                        </label>
                                                        <DatePicker
                                                            disabled={!values.date}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            name="due_date"
                                                            className="form-control"
                                                            filterTime={filterPassedTime}
                                                            minDate={new Date(values.date)}
                                                            dateFormat="MMM d, yyyy"
                                                            selected={
                                                                values.due_date ? moment(values.due_date).toDate() : ""
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue("due_date", date);
                                                            }}
                                                        />
                                                        {errors.due_date && touched.due_date && (
                                                            <div className="text-danger mt-2 ms-1 h5">
                                                                {errors.due_date}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {
                                                        values.invoice &&
                                                        <div className='form-group'>
                                                            <label className='form-label mb-0'>Amount<span className='text-danger'> *</span></label>
                                                            <input className='form-control bg-light' type='money' name='amount' placeholder='Amount' value={`Rs.${values.amount}`} disabled />
                                                        </div>
                                                    }
                                                    <Spinner show={spinner}>
                                                        <button className='btn w-100 btn-primary' type='submit'>Create Invoice</button>
                                                    </Spinner>
                                                    <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                                        Cancel
                                                    </div>
                                                </form>
                                            </Spinner>
                                        )
                                    }}
                                </Formik> : !noZoho &&
                                <>
                                    <h4 className='m-0 p-2 text-center bg-warning'>
                                        <i className="fa fa-warning me-2" />
                                        Society is not mapped to the zoho account please contact the admins to map the society
                                    </h4>
                                    <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                        Cancel
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MobileCreateInvoice
