import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '../../../../components/spinner'
import Header from '../../../../layouts/header'
import { getSociety } from '../../../../graphql/queries'
import { API } from 'aws-amplify'

function MobileRecurringInvoiceDetailsPage() {
    const params = useParams()
    const navigate = useNavigate()

    const [zohoId, setZohoId] = useState()
    const [recurringInvoice, setRecurringInvoice] = useState()
    const [spinner, showSpinner] = useState(false)

    const handelGetSociety = () => {
        showSpinner(true)
        API.graphql({
            query: getSociety,
            variables: {
                id: params.societyId
            }
        }).then((res) => {
            setZohoId(res?.data?.getSociety?.zohoId)
        })
    }

    useEffect(() => {
        handelGetSociety()
        // eslint-disable-next-line
    }, [])

    const handelGetRecurringInvoice = () => {
        if (zohoId) {
            showSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices/${params.invoiceId}?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setRecurringInvoice(res?.data?.recurring_invoice)
                showSpinner(false)
            }).catch((err) => {
                showSpinner(false)
            })
        } else {
            showSpinner(false)
        }
    }

    const handelGoback = () => {
        navigate(`/mobile/${params?.userId}/${params?.societyId}/accounting/recurring-invoice`)
    }

    useEffect(() => {
        handelGetRecurringInvoice()
        // eslint-disable-next-line
    }, [zohoId])

    return (
        <section className='container-fluid mt-3 mx-0 px-0 px-lg-0'>
            <Spinner show={spinner}>
                <div className="row d-flex justify-content-center p-sm-0">
                    <div className="col-lg-8 mx-auto">
                        <div className='px-2'>
                            <Header title={'Recurring Invoice Details'} onclick={handelGoback} buttonTitle={'Back'} />
                        </div>
                        <div className='card box py-5 mx-0 px-0 px-lg-3 mx-3 mx-lg-5'>
                            <div className="ribbon ribbon-top-left">
                                <span className={recurringInvoice?.status === 'active' ? 'bg-success' : 'bg-danger'}>{recurringInvoice?.status}</span>
                            </div>
                            <div className="card-body">
                                <table className='table table-bordered mb-0'>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span className="fs-3">{recurringInvoice?.customer_name}<br /></span>
                                                    <span id="tmp_org_address">India</span>
                                                </div>
                                            </td>
                                            <td align="right">
                                                <div className="pcs-entity-title">TAX INVOICE</div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div>
                                    <table className="table table-bordered m-0">
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='d-flex justify-content-around'>
                                                        <div>
                                                            <div>#</div>
                                                            <div>Start Date</div>
                                                            <div>End Date</div>
                                                        </div>
                                                        <div>
                                                            <div>:</div>
                                                            <div>:</div>
                                                            <div>:</div>
                                                        </div>
                                                        <div>
                                                            <div> {recurringInvoice?.recurrence_name}</div>
                                                            <div> {recurringInvoice?.start_date}</div>
                                                            <div> {recurringInvoice?.end_date}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='border bg-light px-3 py-1'>
                                        <div>Bill To</div>
                                    </div>
                                    <div className='border p-1 px-3'>
                                        <div className='text-primary'>{recurringInvoice?.customer_name}</div>
                                    </div>
                                    <table className="table table-bordered m-0">
                                        <thead>
                                            <tr>
                                                <th className='p-2 ps-3'>#</th>
                                                <th className='p-2 ps-3'>Item & Description</th>
                                                <th className='p-2 ps-3'>Amount</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='p-2 ps-3'> 1</td>
                                                <td className='p-2 ps-3'>{recurringInvoice?.line_items[0]?.name}</td>
                                                <td className='p-2 ps-3'>₹ {recurringInvoice?.line_items[0]?.rate}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className='d-flex justify-content-around border py-3'>
                                        <div>
                                            <div>Total</div>
                                        </div>
                                        <div>
                                            <div>:</div>
                                        </div>
                                        <div>
                                            <div>₹ {recurringInvoice?.line_items[0]?.rate}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spinner>
        </section>
    )
}

export default MobileRecurringInvoiceDetailsPage
