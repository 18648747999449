import React from 'react'
import { useSelector } from 'react-redux'
import FormControl from '../../../components/formControl';
import * as Yup from 'yup'
import { Formik } from 'formik';
import Spinner from '../../../components/spinner';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { createComplaintType } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
const priorityTypr = require('../../../data/complaintPriorityTypa.json');


function CreateComplaint() {
    const navigate = useNavigate()
    let society = useSelector((state) => state.user_society);
    const [spinner, showSpinner] = useState(false)

    const formData = {
        complaint_name: '',
        priority: '',
    }

    const formSchema = Yup.object().shape({
        complaint_name: Yup.string().required('Required.'),
        priority: Yup.string().required('Required'),
    })

    const handleCreateComplaintType = async (form) => {
        showSpinner(true)
        try {
            await API.graphql({
                query: createComplaintType,
                variables: {
                    input: {
                        societyId: society.id,
                        name: form.complaint_name,
                        priorityType: form.priority
                    }
                }
            })
            setTimeout(() => {
                navigate('/complaint')
                toast.success(`Complaint Type created Successfully`)
            }, 3000)
        } catch (err) {
            console.log(err)
        }
    }

    const handleCnacleCreate = () => {
        navigate('/complaint')
    }

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society?.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Complaint Type
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateComplaintType} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors }) => {
                                const listPriority = values.priority ? priorityTypr.filter((x) => x.value === values.priority)[0] : null;
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <FormControl type='text' title='Complaint Name' name='complaint_name' placeholder='Complaint Name' value={values.complaint_name} error={errors.complaint_name} onChange={handleChange} required={true} />

                                        <FormControl type='select1' title='Select type' name='priority' placeholder={'Description'} value={listPriority} options={priorityTypr} error={errors.priority} onChange={handleChange} required={true} />


                                        <Spinner show={spinner}>
                                            <button className='btn w-100 btn-primary' type='submit'>Create Complaint Type</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateComplaint
