import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Booking from './booking'
import FacilityType from './facilityType'
// import Complaint from './complaint'
// import ComplaintType from './complaintType'


function FacilityAdminLayout() {
    const navigate = useNavigate()

    const [page, setPage] = useState('facility_type')

    const handleCreateComplaint = () => {
        navigate('/create/facility_type')
    }


    return (
        <>
            <div className="header">
                <div className="header-body d-flex align-items-end justify-content-between">
                    <div className="row d-flex align-items-center">
                        <div className="col">
                            <ul className="nav nav-tabs nav-overflow header-tabs">
                                <li className="nav-item" onClick={() => setPage('facility_type')}>
                                    <Link className={page === 'facility_type' ? 'nav-link active' : "nav-link"}>
                                        Facility Type
                                    </Link>
                                </li>
                                <li className="nav-item" onClick={() => setPage('booking')}>
                                    <Link className={page === 'booking' ? 'nav-link active' : "nav-link"}>
                                        Booking
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    {
                        page === 'facility_type' &&
                        <div>
                            <button className='btn btn-primary' onClick={handleCreateComplaint}>Create Facility Type</button>
                        </div>
                    }
                </div>
            </div>
            <div className='tab-content'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    {
                        page === 'facility_type' && <FacilityType />
                    }
                    {
                        page === 'booking' && <Booking />
                    }
                </div>
            </div>
        </>
    )
}

export default FacilityAdminLayout
