import { API } from 'aws-amplify'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import FormControl from '../../../components/formControl'
import Spinner from '../../../components/spinner'
import { customListSocietyUsers, customSearchComplaints, customUpdateComplaint } from '../../../custumQueries/custumQueries'

function Complaint() {
    const complaintStatus = require('../../../data/complaintStatus.json')
    const society = useSelector((state) => state.user_society)

    const [spinner, showSpinner] = useState(false);
    const [societyUsers, setSocietyUsers] = useState([])
    const [nextArr, setNextArr] = useState([])
    const [modal, showModal] = useState(false)
    const [complaintList, setComplaintList] = useState([])
    const [formData, setFormData] = useState({
        name: '',
        assignedTo: '',
        complaint_type: '',
        id: ''
    })

    useEffect(() => {
        societyUserLoop()
        handleGetComplaints(null, true)
        // eslint-disable-next-line
    }, [])

    const societyUserLoop = async (nextToken = null) => {
        showSpinner(true)
        do {
            const result = await handleGetSocietyUsers(nextToken);
            nextToken = result.nextToken;
        } while (nextToken)
        showSpinner(false)
    }

    const handleGetSocietyUsers = async (token) => {
        const response = await API.graphql({
            query: customListSocietyUsers,
            variables: {
                filter: { societyId: { eq: society.id } },
                limit: 100,
                nextToken: token
            }
        })
        for (let [, user] of response.data.listSocietyUsers.items.entries()) {
            user.label = user?.user?.name;
            user.value = user?.id && user?.id
        }
        if (!isEmpty(response.data.listSocietyUsers.items)) {
            setSocietyUsers(societyUsers ? societyUsers.concat(response.data.listSocietyUsers.items) : response.data.listSocietyUsers.items)
        }
        const { nextToken } = response.data.listSocietyUsers;
        return { nextToken };
    }

    const handleGetComplaints = (token, prev) => {
        API.graphql({
            query: customSearchComplaints,
            variables: {
                filter: { societyId: { eq: society.id } },
                limit: 10,
                nextToken: token || null
            },
        }).then((res) => {
            if (res?.data?.searchComplaints?.nextToken !== null && prev) {
                let newUsers = res?.data?.searchComplaints?.nextToken
                setNextArr((prev) => ([...prev, newUsers]))
            }
            if (!isEmpty(res.data.searchComplaints.items)) {
                setComplaintList(res.data.searchComplaints.items)
            }
        })
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            handleGetComplaints(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            handleGetComplaints(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            handleGetComplaints(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }


    const handleEdit = (form) => {
        setFormData({
            name: form?.complainType.name,
            id: form?.id,
            assignedTo: { 'label': form?.assignedTo?.user?.name, value: form?.assignedTo?.id },
            complaint_type: form?.status
        })
        showModal(true)
    }

    const handleDeleteResident = () => {

    }

    const handleEditComplaint = async (form) => {
        showSpinner(true)
        try {
            let updatedData = await API.graphql({
                query: customUpdateComplaint,
                variables: {
                    input: {
                        id: formData.id,
                        complaintAssignedToId: form.assignedTo.value,
                        status: form.complaint_type
                    }
                }
            })
            setComplaintList((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index].assignedTo = updatedData.data.updateComplaint.assignedTo;
                prevState[index].status = updatedData.data.updateComplaint.status
                return [...prevState];
            });
            toast.success('Complaint updated successfully')
            showModal(false)
            showSpinner(false)
        }
        catch (err) {
            console.log(err)
        }
    }


    return (
        <section>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card" data-list="{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}" id="companiesList">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <form onSubmit={(event) => { event.preventDefault() }}>
                                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                            <input className="form-control list-search" type="search" placeholder="Search" />
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Spinner show={spinner}>
                                <table className="table table-hover table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Media</th>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Assigned to</th>
                                            <th className='text-center'>Details</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Unit</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list fs-base">
                                        {
                                            complaintList?.map((item, index) => (
                                                <tr key={index}>
                                                    {
                                                        !isEmpty(item?.media) ?
                                                            <td className='text-center'>
                                                                <div className='avatar avatar-xs align-middle me-2'>
                                                                    <img className="avatar-img rounded-circle" src={(item?.media)} alt="..." />
                                                                </div>
                                                            </td>
                                                            :
                                                            <td className='text-center'>
                                                                <span><i className='fe fe-image'></i></span>
                                                            </td>
                                                    }
                                                    <td className='text-center'>{item?.complainType?.name}</td>
                                                    <td className='text-center'>{item?.assignedTo?.user?.name}</td>
                                                    <td className='text-center'></td>
                                                    <td className='text-center'>{item?.status}</td>
                                                    <td className='text-center'>{item?.resident?.unit?.name}</td>
                                                    <td className='text-center'>
                                                        <div className='dropdown'>
                                                            <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                                <i className='fe fe-more-vertical'></i>
                                                            </Link>
                                                            <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                                <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                    Edit
                                                                </div>
                                                                <div className='dropdown-item cursor-pointer' onClick={() => { handleDeleteResident(item, index) }}>
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        <div className='card-footer d-flex justify-content-between'>
                            <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                <li className='page-item' >
                                    <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                        <i className='fe fe-arrow-left me-1'></i> Prev
                                    </Link>
                                </li>
                            </ul>
                            <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                <li className='page-item'>
                                    <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                        Next <i className='fe fe-arrow-right ms-1'></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Complaint</h3>
                    <hr />
                    <Formik initialValues={formData} onSubmit={handleEditComplaint} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors }) => {
                            const type = values?.complaint_type ? complaintStatus?.filter((x) => x?.value === values?.complaint_type)[0] : null;
                            return (
                                <form noValidate onSubmit={handleSubmit}>
                                    <FormControl type='text' name='name' title='Name' value={values?.name} disabled={true} />

                                    <FormControl type='select' title='Assigned To' name='assignedTo' value={values?.assignedTo} options={societyUsers} error={errors.assignedTo} onChange={handleChange} />

                                    <FormControl type='select1' title='Select type' name='complaint_type' value={type} options={complaintStatus} error={errors.complaint_type} onChange={handleChange} />

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Complaint
