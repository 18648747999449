import { Formik } from 'formik'
import React, { useState } from 'react'
import Spinner from '../../components/spinner'
import * as Yup from 'yup';
import NumberFormat from 'react-number-format'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { API } from 'aws-amplify'
import { updateUser } from '../../graphql/mutations'
import { useNavigate } from 'react-router-dom';

function Profile() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userDetails = useSelector(store => store.user_profile)
    const society = useSelector(store => store.user_society)

    const [spinner, showSpinner] = useState()
    const [user, setUsers] = useState({
        name: '',
        image: '',
        id: '',
        title: '',
        email: '',
        phone: ''
    })

    const profileSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
    });

    useEffect(() => {
        setUsers({
            name: userDetails?.name,
            image: userDetails?.photo,
            id: userDetails?.id,
            title: 'ADMIN',
            email: userDetails?.email.trim().toLowerCase(),
            phone: userDetails?.phone?.split('+91')[1]
        })
        // eslint-disable-next-line
    }, [userDetails])


    const handleSubmit = (form) => {
        showSpinner(true)
        try {
            API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: user?.id,
                        name: form?.name,
                    }
                }
            }).then((res) => {
                dispatch({ type: 'user_profile', payload: res?.data?.updateUser })
            }).catch((err) => {
                console.log(err)
            })
            setTimeout(() => {
                showSpinner(false)
            }, 1000)
        } catch (err) {
            console.log(err)
        }
    }

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <section>
            <nav className="header">
                <div className="container">
                    <div className="header-body">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="col">
                                <h6 className="header-pretitle">{society?.name}</h6>
                                <h1 className="header-title">My Profile</h1>
                            </div>
                            <div >
                                <button className='btn btn-primary' onClick={() => handleGoBack()}>Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-md-7 col-lg-6'>
                        <div className='card mt-5'>
                            <div className='card-body'>
                                <Spinner show={spinner}>
                                    <Formik initialValues={user} validationSchema={profileSchema} enableReinitialize onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
                                        {({ handleChange, handleSubmit, values, errors, touched }) => (
                                            <form noValidate onSubmit={handleSubmit}>
                                                <div className='form-group'>
                                                    <label className='form-label'>
                                                        Full Name<span className='text-danger'>*</span>
                                                    </label>
                                                    <input className='form-control' type='text' name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} />
                                                    {errors.name && touched.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>Title<span className='text-danger'>*</span></label>
                                                    <input className='form-control bg-light' type='text' name='title' placeholder='e.g. Investor' value={values.title} onChange={handleChange} disabled />
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>
                                                        Email Address<span className='text-danger'>*</span>
                                                    </label>
                                                    <input className='form-control bg-light' disabled type='text' name='email' placeholder='e.g. address@example.com' value={values.email} onChange={handleChange} />
                                                </div>
                                                <div className='form-group'>
                                                    <label className='form-label'>Phone</label>
                                                    <NumberFormat
                                                        disabled
                                                        placeholder='eg: +91 (444) 444-4444'
                                                        className='form-control bg-light'
                                                        format='+91 (###) ###-####'
                                                        mask='_'
                                                        value={values.phone}
                                                    />
                                                    {errors.phone && touched.phone && <div className='text-danger mt-2 ms-1 h5'>{errors.phone}</div>}
                                                </div>
                                                <Spinner show={spinner}>
                                                    <button type='submit' form='profile-form' className='btn btn-primary ms-2' onClick={handleSubmit}>
                                                        Update Profile
                                                    </button>
                                                </Spinner>
                                            </form>
                                        )}
                                    </Formik>
                                </Spinner>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-5 col-lg-6 d-none d-lg-flex'>
                        <img src='/img/profile.svg' className='img-fluid' alt='...' data-aos='fade-up' data-aos-delay='100' />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Profile
