import { API } from 'aws-amplify'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormControl from '../../../../components/formControl'
import Spinner from '../../../../components/spinner'
import { customSearchBuildings, customSearchResidentUsers, customSearchUnits, } from '../../../../custumQueries/custumQueries'
import { createResident } from '../../../../graphql/mutations'
import { searchResidents } from '../../../../graphql/queries'

function AddResident() {
    const residentType = require('../../../../data/residentData.json')
    const approvedData = require('../../../../data/apporved.json')
    const navigate = useNavigate()

    const [spinner, showSpinner] = useState()
    const society = useSelector((store) => store.user_society)

    const [buildings, setBuildings] = useState()
    const [units, setUnits] = useState()
    const formData = {
        phone: '',
        building: '',
        unit: '',
        resident_type: '',
        approved: '',
    }

    const formSchema = Yup.object().shape({
        phone: Yup.string().required('Required'),
        building: Yup.object().required('Required'),
        unit: Yup.object().required('Required'),
        resident_type: Yup.string().required('Required'),
        approved: Yup.string().required('Required'),
    })

    useEffect(() => {
        handleFormDataOptions()
        // eslint-disable-next-line
    }, [])

    const handleFormDataOptions = async () => {
        try {
            await API.graphql({
                query: customSearchBuildings,
                variables: {
                    filter: { societyId: { eq: society.id } }
                }
            }).then((res) => {
                for (let buildingItem of res?.data?.searchBuildings?.items) {
                    buildingItem.label = buildingItem.name
                    buildingItem.value = buildingItem.id
                }
                setBuildings(res?.data?.searchBuildings?.items)
            })
            await API.graphql({
                query: customSearchUnits,
                variables: {
                    filter: { societyId: { eq: society.id } }
                }
            }).then((res) => {
                for (let item of res?.data?.searchUnits.items) {
                    item.label = item.name
                    item.value = item.id
                }
                setUnits(res?.data?.searchUnits.items)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const handleAddResident = async (form) => {
        showSpinner(true)
        try {
            const userData = await API.graphql({
                query: customSearchResidentUsers,
                variables: {
                    filter: { phone: { eq: `+91${form.phone}` } }
                }
            })

            const userId = userData?.data?.searchUsers?.items[0]?.id;

            if (!userId) {
                toast.error('User ID not found');
                showSpinner(false); 
                return;
            }

            const response = await API.graphql({
                query: searchResidents,
                variables: {
                    filter: {
                        and: [
                            { userId: { eq: userId } },
                            { societyId: { eq: society?.id } }
                        ]
                    }
                }
            });

            const residents = response?.data?.searchResidents?.items;
            if (residents && residents.length > 0) {
                toast.error('Resident Already Exist');
                showSpinner(false); 
                return; 
            }

            if (!isEmpty(userData?.data?.searchUsers?.items)) {
                if ((!isEmpty(userData?.data?.searchUsers?.items[0]?.residents?.items)) && userData?.data?.searchUsers?.items[0]?.residents?.items.some(x => x.buildingId === form?.building?.id && x.unitId === form?.unit?.id)) {
                    toast.warning(`+91${form.phone} was already exist in ${form?.building?.label} under ${form?.unit?.label}`)
                    showSpinner(false)
                }
                else {
                    let approved = form.approved === 'Approve' ? "true" : "false"
                    await API.graphql({
                        query: createResident,
                        variables: {
                            input: {
                                name: form.name,
                                societyId: society.id,
                                buildingId: form.building.id,
                                unitId: form.unit.id,
                                userId: userData?.data?.searchUsers?.items[0]?.id,
                                type: form.resident_type,
                                approved: approved
                            }
                        }
                    }).then(() => {
                        setTimeout(() => {
                            toast.success(`Resident added successfully`);
                            showSpinner(false)
                            navigate('/resident')
                        }, 3000)
                    }).catch(err => {
                        console.log(err)
                    })

                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const handelSetUnit = async (e) => {
        await API.graphql({
            query: customSearchUnits,
            variables: {
                filter: { societyId: { eq: society.id }, buildingId: { eq: e?.target?.value?.id } }
            }
        }).then((res) => {
            for (let item of res?.data?.searchUnits.items) {
                item.label = item.name
                item.value = item.id
            }
            setUnits(res?.data?.searchUnits.items)
        })
    }

    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Add Resident
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleAddResident} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, values, errors }) => {
                                const listResidentType = values.resident_type ? residentType.filter((x) => x.value === values.resident_type)[0] : null;
                                const listApprovedData = values.approved ? approvedData.filter((x) => x.value === values.approved)[0] : null;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl type="phone" title="Phone" name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />
                                        <FormControl type='select' name='building' title='Select building' options={buildings} required={true}
                                            value={values.building} error={errors.building} onChange={(e) => { handleChange(e); handelSetUnit(e) }} />

                                        {values?.building &&
                                            <FormControl type='select' name='unit' title='Select Unit' options={units} required={true}
                                                value={values.unit} error={errors.unit} onChange={handleChange} />
                                        }

                                        <FormControl type='select1' title='Select type' name='resident_type' placeholder={'Description'} value={listResidentType} options={residentType} error={errors.resident_type} onChange={handleChange} required={true} />

                                        <FormControl type='select1' title='Select status' name='approved' placeholder={'Description'} value={listApprovedData} options={approvedData} error={errors.approved} onChange={handleChange} required={true} />
                                        <div>
                                            <Spinner show={spinner}>
                                                <button className="btn w-100 btn-primary" type='submit'>Add Resident</button>
                                            </Spinner>
                                            <Link to={`/resident`}>
                                                <div className='btn w-100 btn-link text-muted mt-2' type='button'>
                                                    Cancel
                                                </div>
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        <div className='text-muted text-start' type='button'>
                            New User? <Link to={`/resident/create`}>Click here</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddResident
