import React from 'react'

function Billing() {
    return (
        <h3>
            Billing
        </h3>
    )
}

export default Billing
