import React, { useEffect, useState } from 'react'
import FormControl from '../../../components/formControl'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Link, useNavigate } from 'react-router-dom'
import { createUser, createSocietyUser } from '../../../graphql/mutations'
import { API, Auth, Storage } from 'aws-amplify';
import Spinner from '../../../components/spinner'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { searchUsers } from '../../../graphql/queries'
import { isEmpty } from 'lodash'


const CreateUser = () => {
    const userRole = require('../../../data/societyUserRole.json')
    const society = useSelector((store) => store.user_society)
    const navigate = useNavigate()
    const { userId } = useParams()

    const [spinner, showSpinner] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        image: '',
        role: '',
    })

    const myquery = `query GetUser {
        getUser(id: "${userId}") {
          id
          name
          email
          phone
          photo
          societies {
            items {
              id
              society {
                id
                name
              }
            }
          }
        }
      }`

    useEffect(() => {
        if (userId) {
            fetchSocietyUsers()
        }
        // eslint-disable-next-line
    }, [userId])


    const fetchSocietyUsers = async () => {
        showSpinner(true)
        await API.graphql({
            query: myquery,
        }).then(res => {

            const obj = {
                name: res.data.getUser.name,
                email: res.data.getUser.email,
                phone: res.data.getUser.phone,
                image: res.data.getUser.photo,
            }
            if (res?.data?.getUser?.societies?.items?.length > 0) {
                // eslint-disable-next-line
                res?.data?.getUser?.societies?.items?.map((item) => {
                    obj?.society.push({
                        value: society.id,
                        label: item.society.name
                    })
                })
            }
            setFormData(() => (obj))
            showSpinner(false)
        }).catch(err => {
            console.log(err)
            showSpinner(false)
        })
    }

    const formSchema = Yup.object().shape({
        name: Yup.string().required('Required'),
        // eslint-disable-next-line
        email: Yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'Please enter valid email').required('Required'),
        phone: Yup.string().required('Required'),
        image: Yup.string().required('Required'),
        role: Yup.object().required('Required'),

    })

    const handleUploadImage = async (e, callback, name) => {
        try {
            const file = e.target.files[0]
            const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
            const url = await Storage.get(stored.key, { level: 'public' })
            let ImageUrl = url.split('?')[0]
            if (ImageUrl) {
                const event = { target: { name: name || '', value: ImageUrl } }
                callback(event)
            }
        } catch (error) {
            console.log(error)
        }

    }

    const addSocieties = async (uID, form) => {
        try {
            await API.graphql({
                query: createSocietyUser,
                variables: {
                    input: {
                        name: form.name,
                        role: form.role.label,
                        societyId: society.id,
                        userId: uID,
                    }
                }
            }).then(res => {
                setTimeout(() => {
                    toast.success('User created and added successfully')
                    navigate(`/society-staff`)
                    showSpinner(false)
                }, 2000)
            }).catch((err) => console.log(err))
        }
        catch (error) {
            console.log("error", error)
        }
    }

    const handleSubmit = async (form) => {
        showSpinner(true)
        const userData = {
            phone: `+91${form.phone}`,
            password: 'Buckler@123',
        }
        try {
            let userDetails = await API.graphql({
                query: searchUsers,
                variables: {
                    filter: { email: { eq: form.email } }
                }
            })
            if (isEmpty(userDetails?.data?.searchUsers?.items)) {
                const user = await Auth.signUp({
                    username: userData.phone,
                    password: userData.password,
                    attributes: {
                        email: form.email.trim().toLowerCase(),
                        phone_number: `+91${form.phone}`
                    },
                })
                if (user.userSub) {
                    await API.graphql({
                        query: createUser,
                        variables: {
                            input: {
                                id: user.userSub,
                                name: form?.name,
                                email: form.email,
                                phone: userData.phone,
                                photo: form.image
                            }
                        }
                    }).then((res) => {
                        if (res) {
                            addSocieties(res.data.createUser.id, form)
                        }
                    })
                }
            }
            else {
                toast.error('This email already exists')
                showSpinner(false)
            }
        } catch (err) {
            console.log(err)
            toast.error(err.message)
        }
    }

    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Create User
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, setFieldValue, values, touched, errors }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl type="text" title="Name" name='name' placeholder={'Name'} value={values.name} error={errors.name} onChange={handleChange} required={true} />

                                        <FormControl type="email" title="Email" name='email' placeholder={'Email'} value={values.email} error={errors.email} onChange={handleChange} required={true} />

                                        <FormControl type="phone" title="Phone" name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />

                                        <FormControl type='select' name='role' title='Select Role' options={userRole} required={true}
                                            value={values.role} error={errors.role} onChange={handleChange} />

                                        {errors.society && touched.society ? (<div className='required'>{errors.society}</div>) : null}
                                        <div className='form-group mt-3'>
                                            {values?.image && <img src={values?.image} alt="" width={'200'} className='preview' />}
                                            <div>
                                                <label>Image</label>
                                                <input name="image" type="file" accept='image/*' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className="form-control" />
                                                {errors.image && touched.image && <div className='text-danger mt-2 ms-1 h5'>{errors.image}</div>}
                                            </div>
                                        </div>
                                        <div>
                                            <Spinner show={spinner}>
                                                <button className="btn w-100 btn-primary" type='submit'>Create User</button>
                                            </Spinner>
                                            <Link to={`/society-staff`}>
                                                <div className='btn w-100 btn-link text-muted mt-2' type='button'>
                                                    Cancel
                                                </div>
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        <div className='btn w-100 btn-link text-muted text-start' type='button'>
                            User Already Exist? <Link to={`/add/society-staff`}>Click here</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateUser
