import { API } from 'aws-amplify'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { customSearchAmenityBookings } from '../../../custumQueries/custumQueries'
import { useEffect } from 'react'
import { isEmpty } from 'lodash'
import * as Yup from 'yup';
import Spinner from '../../../components/spinner'
import { Link } from 'react-router-dom'
import { Modal } from 'react-bootstrap'
import FormControl from '../../../components/formControl'
import { Formik } from 'formik'
import { deleteAmenityBooking, updateAmenityBooking } from '../../../graphql/mutations'
import { toast } from 'react-toastify'
import swal from 'sweetalert'

function Booking() {
    const bookingStatus = require('../../../data/bookingStatus.json')
    const society = useSelector(store => store.user_society)

    const [search, setSearch] = useState()
    const [modal, showModal] = useState()
    const [spinner, showSpinner] = useState()
    const [nextArr, setNextArr] = useState([])
    const [aminityList, setAminityList] = useState()
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        unit_name: '',
        status: '',
        amount: ''
    })

    const formSchema = Yup.object().shape({
    })

    const handelGetAminityes = (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchAmenityBookings,
                variables: {
                    filter: { societyId: { eq: society.id } },
                    sort: { direction: 'desc', field: 'createdAt' },
                    limit: 20,
                    nextToken: token || null
                }
            }).then(res => {
                if (!isEmpty(res?.data?.searchAmenityBookings?.items)) {
                    if (res?.data?.searchAmenityBookings?.nextToken !== null && prev) {
                        let newUsers = res?.data?.searchAmenityBookings?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setAminityList(res?.data?.searchAmenityBookings?.items)
                }
            }
            )
            showSpinner(false)
        }
        catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            handelGetAminityes(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            handelGetAminityes(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            handelGetAminityes(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const handelSearchSociety = (e) => {
        if (e.target.value) {
            setSearch(e.target.value)
            API.graphql({
                query: customSearchAmenityBookings,
                variables: {
                    filter: { name: { matchPhrasePrefix: e.target.value }, societyId: { eq: society.id } }
                }
            }).then((res) => {
                setAminityList(res.data.searchSocieties.items)
            })
        } else {
            setSearch('')
            handelGetAminityes(nextArr[nextArr.length - 2], false)
        }
    }

    const handleEdit = (data) => {
        setFormData({
            name: data?.amenity?.name,
            id: data?.id,
            unit_name: data?.unit?.name,
            status: { value: data?.status, label: data?.status },
            amount: data?.amount ? data?.amount : 'Free of cost',
        })
        showModal(true)
    }

    const handelDeleteAmminity = (Amminity, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Resident?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteAmenityBooking,
                    variables: {
                        input: {
                            id: Amminity?.id
                        }
                    }
                }).then(() => {
                    const temp = [...aminityList]
                    temp.splice(index, 1)
                    setAminityList(temp)
                    toast.success('Booking deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    const handelEditAmminity = async (form) => {
        try {
            let updatedData = await API.graphql({
                query: updateAmenityBooking,
                variables: {
                    input: {
                        id: formData.id,
                        status: form?.status?.value
                    }
                }
            })
            setAminityList((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index].status = updatedData?.data?.updateAmenityBooking?.status;
                return [...prevState];
            });
            showModal(false)
            toast.success('Updated successfully')
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        handelGetAminityes(null, true)
        // eslint-disable-next-line
    }, [])

    return (
        <section>
            <div className="tab-content">
                <div className="tab-pane fade active show" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card" data-list="{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}" id="companiesList">
                        <div className='card-header'>
                            <div className='row align-items-center'>
                                <div className='col'>
                                    <form onSubmit={(event) => { event.preventDefault() }}>
                                        <div className='input-group input-group-flush input-group-merge input-group-reverse'>
                                            <input className='form-control list-search' type='search' placeholder='Search' onChange={(e) => handelSearchSociety(e)} />
                                            <span className='input-group-text'>
                                                <i className='fe fe-search'></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Spinner show={spinner}>
                                <table className="table table-hover table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Unit name</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Invoice Date</th>
                                            <th className='text-center'>Amount</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list fs-base">
                                        {
                                            aminityList?.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='text-center'>{item?.amenity?.name}</td>
                                                    <td className='text-center'>{item?.unit?.name}</td>
                                                    <td className='text-center'>{item?.status}</td>
                                                    <td className='text-center'>{item?.invoiceDate}</td>
                                                    <td className='text-center'>{item?.amount ? <>₹ {item?.amount}</> : <>Free of cost</>}</td>
                                                    <td className='text-center'>
                                                        <div className='dropdown'>
                                                            <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                                <i className='fe fe-more-vertical'></i>
                                                            </Link>
                                                            <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                                <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                    Edit
                                                                </div>
                                                                <div className='dropdown-item cursor-pointer' onClick={() => { handelDeleteAmminity(item, index) }}>
                                                                    Delete
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        {!search &&
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Booking Status</h3>
                    <hr />
                    <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handelEditAmminity} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                        {({ handleChange, handleSubmit, values, errors, touched }) => {
                            return (
                                <form noValidate onSubmit={handleSubmit}>

                                    <div className='form-group'>
                                        <label className='form-label text-muted'>
                                            Name<span className='text-danger'>*</span>
                                        </label>
                                        <input className='form-control bg-light' type='text' disabled name='name' placeholder='e.g. John Doe' value={values.name} onChange={handleChange} />
                                        {errors.name && touched.name && <div className='text-danger mt-2 ms-1 h5'>{errors.name}</div>}
                                    </div>

                                    <div className='form-group'>
                                        <label className='form-label text-muted'>
                                            Unit Name<span className='text-danger'>*</span>
                                        </label>
                                        <input className='form-control bg-light' type='text' disabled name='unit_name' placeholder='e.g. John Doe' value={values.unit_name} onChange={handleChange} />
                                        {errors.unit_name && touched.unit_name && <div className='text-danger mt-2 ms-1 h5'>{errors.unit_name}</div>}
                                    </div>

                                    <div className='form-group'>
                                        <label className='form-label text-muted'>
                                            Amount<span className='text-danger'>*</span>
                                        </label>
                                        <input className='form-control bg-light' type='text' disabled name='amount' placeholder='e.g. John Doe' value={values.amount} onChange={handleChange} />
                                        {errors.amount && touched.amount && <div className='text-danger mt-2 ms-1 h5'>{errors.amount}</div>}
                                    </div>

                                    <FormControl type='select' required title='Select type' name='status' value={values?.status} options={bookingStatus} error={errors.status} onChange={handleChange} />

                                    <Spinner show={spinner}>
                                        <button className='btn btn-primary' type='submit'>Update</button>
                                        <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                    </Spinner>
                                </form>
                            )
                        }
                        }
                    </Formik>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Booking
